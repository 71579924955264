<template>
	<div>
		<v-row>
			<v-col class="col-12 col-sm-3 col-md-3 col-lg-2 d-none d-md-block">
                 <User-Side-Bar/> 
            </v-col>
            <v-col class="col-md-9 col-lg-10 grey lighten-3 px-2 pl-md-4">
                <div :class="{'pr-8': $vuetify.breakpoint.smAndDown}">
                    <h3 class="mb-6 mt-2 grey--text text--darken-4 text-md-h6">
                        <router-link class="black--text text-decoration-none" :to="`/dashboard`">
                            <v-icon class='mt-n1 mr-2 hidden-md-and-up'>mdi-arrow-left</v-icon>
                        </router-link>  
                        Verify User
                    </h3>
                    <div class="float-right mt-n8 mr-md-6">
						<Notification/>		
					</div>    
                </div>
				<div v-show="skeLoader">
					<skeleton-loader/>
				</div>
				<v-tabs
					class="mb-8"
					v-model="tab"
					align-with-title
					
				>
					<v-tabs-slider color="yellow"></v-tabs-slider>

					<v-tab
					>
						Verified <span class="grey--text">({{verifiedUsers.length}})</span>
					</v-tab>
					<v-tab
					>
						Pending <span class="grey--text">({{pendingUsers.length}})</span>
					</v-tab>
					<v-tab
					>
						Declined <span class="grey--text">({{declinedUsers.length}})</span>
					</v-tab>
				</v-tabs>
				<v-tabs-items v-model="tab">
					<v-tab-item>
						<v-data-table
							v-show="skeLoader == false"
							:headers="headers"
							:items="verifiedUsers"
							sort-by="title"
							class="elevation-1"
							:search="search"
						>   
							<template v-slot:top>
								<v-toolbar
								flat
								>
									<v-text-field
										v-model="search"
										append-icon="mdi-magnify"
										label="Search"
										single-line
										hide-details
									>
									</v-text-field>
									<v-spacer></v-spacer>
			
									<v-dialog 
                                        v-model="dialogPublish"  
                                        :retain-focus="false"
                                        max-width="500px"
                                    >
										<v-card>
											<v-card-title class="">Verify : <span> {{userName}}</span></v-card-title>
												<div class="px-4">
													<v-select
														v-model="status"
														:items="publishItems"
														item-text="name" 
														item-value="value"
														label="select one"
														outlined
													></v-select>
												</div><br>
												<div class="text-center px-4" v-show="status == 2">
													<p> Reasons for Rejection</p>
													<v-textarea
													outlined
													v-model="message"
													class="mt-2"
													label="type message here"
													></v-textarea>
												</div>
											<v-card-actions>
												<v-spacer></v-spacer>
												<v-btn class="white--text" color="grey darken-3"   @click="closePublish">Cancel</v-btn>
												<v-btn v-show="loading == false"  class="white--text" color="green lighten-2"  @click="publishItemConfirm">OK</v-btn>
												<v-progress-circular
													v-show="loading"
													:width="3"
													class="ml-4"
													color="green"
													indeterminate
												>
												</v-progress-circular>
												<v-spacer></v-spacer>
											</v-card-actions>
										</v-card>
									</v-dialog>

                                    <v-dialog 
                                        v-model="dialogDocument"  
                                        :retain-focus="false"
                                        fullscreen
                                        hide-overlay
                                    >
										<v-card class="">
                                            <v-card-text class="mt-4">
                                                <v-img
                                                    class=""
                                                    aspect-ratio="1.7"
                                                    contain
                                                    :src="document"
                                                
                                                >
                                                </v-img>
                                            </v-card-text>	
											<v-card-actions>
												<v-spacer></v-spacer>
												<v-btn class="white--text" color="blue"   @click="closeDocument">Close</v-btn>
												<v-spacer></v-spacer>
											</v-card-actions>
										</v-card>
									</v-dialog>
									
								</v-toolbar>
							</template>
							<template v-slot:item.status="{item}">
								{{(item.status  == 0)? ('Pending'): ((item.status == 1) ? ('Verified') : ('Declined'))}}
							
							</template>
							<template v-slot:item.actions="{ item }">
								<v-icon
									class="mr-2"
									small
									@click="publishItem(item)"
								>
									mdi-checkbox-marked-circle
								</v-icon>
                                <v-icon
									class="mr-2"
									small
									@click="documentImage(item.address)"
								>
									mdi-map-marker
								</v-icon>
                                <v-icon
									class="mr-2"
									small
									@click="documentImage(item.id_card)"
								>
									mdi-card-account-details
								</v-icon>
							</template>
						</v-data-table>
					</v-tab-item>

					<v-tab-item>
						<v-data-table
							v-show="skeLoader == false"
							:headers="headers"
							:items="pendingUsers"
							sort-by="title"
							class="elevation-1"
							:search="search"
						>   
							<template v-slot:top>
								<v-toolbar
								flat
								>
									<v-text-field
										v-model="search"
										append-icon="mdi-magnify"
										label="Search"
										single-line
										hide-details
									>
									</v-text-field>
									<v-spacer></v-spacer>
									
									<v-dialog 
                                        v-model="dialogPublish" 
                                        max-width="500px"
                                        :retain-focus="false"
                                    >
										<v-card>
											<v-card-title class="">Verify : <span> {{userName}}</span></v-card-title>
												<div class="px-4">
													<v-select
														v-model="status"
														:items="publishItems"
														item-text="name" 
														item-value="value"
														label="select one"
														outlined
													></v-select>
												</div><br>
												<div class="text-center px-4" v-show="status == 2">
													<p> Reasons for Rejection</p>
													<v-textarea
													outlined
													v-model="message"
													class="mt-2"
													label="type message here"
													></v-textarea>
												</div>
											<v-card-actions>
												<v-spacer></v-spacer>
												<v-btn class="white--text" color="grey darken-3"   @click="closePublish">Cancel</v-btn>
												<v-btn v-show="loading == false"  class="white--text" color="green lighten-2"  @click="publishItemConfirm">OK</v-btn>
												<v-progress-circular
													v-show="loading"
													:width="3"
													class="ml-4"
													color="green"
													indeterminate
												>
												</v-progress-circular>
												<v-spacer></v-spacer>
											</v-card-actions>
										</v-card>
									</v-dialog>
                                     <v-dialog 
                                        v-model="dialogDocument"  
                                        :retain-focus="false"
                                        fullscreen
                                        hide-overlay
                                    >
										<v-card class="">
                                            <v-card-text class="mt-4">
                                                <v-img
                                                    class=""
                                                    aspect-ratio="1.7"
                                                    contain
                                                    :src="document"
                                                
                                                >
                                                </v-img>
                                            </v-card-text>	
											<v-card-actions>
												<v-spacer></v-spacer>
												<v-btn class="white--text" color="blue"   @click="closeDocument">Close</v-btn>
												<v-spacer></v-spacer>
											</v-card-actions>
										</v-card>
									</v-dialog>
								</v-toolbar>
							</template>
							<template v-slot:item.status="{item}">
								{{(item.status  == 0)? ('Pending'): ((item.status == 1) ? ('Verified') : ('Declined'))}}
							
							</template>
							<template v-slot:item.actions="{ item }">
								<v-icon
									class="mr-2"
									small
									@click="publishItem(item)"
								>
									mdi-checkbox-marked-circle
								</v-icon>
                                <v-icon
									class="mr-2"
									small
									@click="documentImage(item.address)"
								>
									mdi-map-marker
								</v-icon>
                                <v-icon
									class="mr-2"
									small
									@click="documentImage(item.id_card)"
								>
									mdi-card-account-details
								</v-icon>
					
							</template>
						</v-data-table>
					</v-tab-item>
					
					<v-tab-item>
						<v-data-table
							v-show="skeLoader == false"
							:headers="headers"
							:items="declinedUsers"
							sort-by="title"
							class="elevation-1"
							:search="search"
						>   
							<template v-slot:top>
								<v-toolbar
								flat
								>
									<v-text-field
										v-model="search"
										append-icon="mdi-magnify"
										label="Search"
										single-line
										hide-details
									>
									</v-text-field>
									<v-spacer></v-spacer>
							
									<v-dialog 
                                        v-model="dialogPublish" 
                                        max-width="500px"
                                        :retain-focus="false"
                                    >
										<v-card>
											<v-card-title class="">Verify : <span> {{userName}}</span></v-card-title>
												<div class="px-4">
													<v-select
														v-model="status"
														:items="publishItems"
														item-text="name" 
														item-value="value"
														label="select one"
														outlined
													></v-select>
												</div><br>
												<div class="text-center px-4" v-show="status == 2">
													<p> Reasons for Rejection</p>
													<v-textarea
													outlined
													v-model="message"
													class="mt-2"
													label="type message here"
													></v-textarea>
												</div>
											<v-card-actions>
												<v-spacer></v-spacer>
												<v-btn class="white--text" color="grey darken-3"   @click="closePublish">Cancel</v-btn>
												<v-btn v-show="loading == false"  class="white--text" color="green lighten-2"  @click="publishItemConfirm">OK</v-btn>
												<v-progress-circular
													v-show="loading"
													:width="3"
													class="ml-4"
													color="green"
													indeterminate
												>
												</v-progress-circular>
												<v-spacer></v-spacer>
											</v-card-actions>
										</v-card>
									</v-dialog>

                                     <v-dialog 
                                        v-model="dialogDocument"  
                                        :retain-focus="false"
                                        fullscreen
                                        hide-overlay
                                    >
										<v-card class="">
                                            <v-card-text class="mt-4">
                                                <v-img
                                                    class=""
                                                    aspect-ratio="1.7"
                                                    contain
                                                    :src="document"
                                                
                                                >
                                                </v-img>
                                            </v-card-text>	
											<v-card-actions>
												<v-spacer></v-spacer>
												<v-btn class="white--text" color="blue"   @click="closeDocument">Close</v-btn>
												<v-spacer></v-spacer>
											</v-card-actions>
										</v-card>
									</v-dialog>
								</v-toolbar>
							</template>
							<template v-slot:item.status="{item}">
								{{(item.status  == 0)? ('Pending'): ((item.status == 1) ? ('Verified') : ('Declined'))}}
							</template>
							<template v-slot:item.actions="{ item }">
								<v-icon
									class="mr-2"
									small
									@click="publishItem(item)"
								>
									mdi-checkbox-marked-circle
								</v-icon>
                                <v-icon
									class="mr-2"
									small
									@click="documentImage(item.address)"
								>
									mdi-map-marker
								</v-icon>
                                <v-icon
									class="mr-2"
									small
									@click="documentImage(item.id_card)"
								>
									mdi-card-account-details
								</v-icon>
							</template>
						</v-data-table>
					</v-tab-item>
				</v-tabs-items>
			</v-col>
		</v-row>
	</div>
</template>

<script>
	import SkeletonLoader from '../layout/SkeletonLoader';
	import { mapActions, mapState } from "vuex";
	import UserSideBar from '../../components/auth/UserSideBar';
	import Notification from '../../components/auth/NotificationWrapper';
	export default {
		components: {UserSideBar, Notification, SkeletonLoader},
		metaInfo() {
			return {
				title: `Verify User - Housefinder`,
				
			}
		},
		data: () => ({
			tab: null,
			allUsers: [],
			message: '',
			skeLoader: false,
			loading: false,
			status: '',
            userName: '',
            document: '',
			publishItems: [
				{name: 'Accept', value: 1},
				{name: 'Reject', value: 2},
			],
			featured: '',
			search: '',
			dialog: false,
			dialogPublish: false,
            dialogDocument: false,
			headers: [
				{
				text: 'Name',
				align: 'start',
				sortable: false,
				value: 'user.full_name',
				},
				{ text: 'Company Name', value: 'company_name' },
				{ text: 'Company Number', value: 'company_number' },
				{ text: 'Id Card-Type', value: 'id_card_type' },
				{ text: 'Email', value: 'user.email' },
                { text: 'Status', value: 'status' },
				{ text: 'Date', value: 'created_at' },
				{ text: 'Actions', value: 'actions', sortable: false },
			],
			editedIndex: '',
			editedItem: {},
			
		}),

		computed: {
			...mapState({verifyUser: state => state.auth.verifyUser}),
			formTitle () {
				return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
			},
			verifiedUsers () {
				return this.allUsers.filter(p => p.status == 1 );
			},
			pendingUsers (){
				return this.allUsers.filter(p => p.status == 0 );
			},
			declinedUsers (){
				return this.allUsers.filter(p => p.status == 2 );
			}
		},

		watch: {
			dialog (val) {
				val || this.close()
			},
			dialogPublish (val) {
				val || this.closePublish()
			},
		},
		methods: {
			...mapActions(["updateVerify","verifyUserList"]),
			editItem (item) {
			
				this.$router.push(`/product/edit/${item.slug}`);
			},

			publishItem (item) {
				this.editedIndex = item.id;
                this.status = item.status;
                this.message = '';
				this.editedItem = item;
                this.userName = item.user.full_name;
				this.dialogPublish = true
			},
            
			documentImage(file) {
				this.document = file;
				this.dialogDocument = true
			},
			async publishItemConfirm () {
				this.loading = true;
				let field = {
                    id: this.editedIndex, 
                    status: this.status,
                    message: this.message
                };
                console.log(field)
				let data = await this.updateVerify(field);
				this.loading = false;
				if(data){
					this.closePublish()
					this.$toastr.s('Record Updated Successfully');
				
			
				}
				else{
					this.$toastr.e(data.message);
					this.closePublish()
				}
				
			},

			close () {
				this.dialog = false
				this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem)
				this.editedIndex = -1
				})
			},

			closePublish () {
				this.dialogPublish = false
				this.$nextTick(() => {
				this.editedItem = {};
				this.editedIndex = ''
				})
			},
            closeDocument () {
				this.dialogDocument = false
				this.$nextTick(() => {
				this.editedItem = {};
				this.editedIndex = ''
				})
			},
			async getData(){
				this.skeLoader = true;
				await this.verifyUserList();
				this.allUsers = this.verifyUser;
                console.log(this.allUsers)
				this.skeLoader = false;

			},
		},
		created () {
			this.getData();
		},
	}
</script>