<template>
    <div>
        <v-row>
            <v-col class="col-12 col-sm-3 col-md-3 col-lg-2 d-none d-md-block">
                 <User-Side-Bar/> 
            </v-col>
            <v-col class="col-md-6 col-lg-7 grey lighten-3 px-2 pl-md-4">
                <div :class="{'pr-8': $vuetify.breakpoint.smAndDown}">
                    <h3 class="mb-6 mt-2 grey--text text--darken-4 text-md-h6">
                        <router-link class="black--text text-decoration-none" :to="`/dashboard`">
                            <v-icon class='mt-n1 mr-2 hidden-md-and-up'>mdi-arrow-left</v-icon>
                        </router-link>  
                         Edit Property
                    </h3>
                    <div class="float-right mt-n8  d-md-none">
						<Notification/>		
					</div>    
                </div>
                <div v-show="skeLoader">
					<skeleton-loader/>
				</div>
                <v-form class="white px-md-2" v-show="skeLoader == false">
                    <v-container>
                        <h4 class="mb-2">Property Description</h4>
                        <v-row >
                            <v-col
                                cols="12"
                            >   
                                <v-text-field
                                    color="grey"
                                    v-model="form.title"
                                    label="Title"
                                    outlined
                                    filled
                                    :error-messages="titleErrors"
                                    @input="$v.form.title.$touch()"
                                    @blur="$v.form.title.$touch()"

                                >
                                </v-text-field>
                            </v-col>
                            <v-col
                                class="mt-n8"
                                cols="12"
                            >
                                 <v-textarea
                                    outlined
                                    v-model="form.description"
                                    name="input-7-4"
                                    filled
                                    label="Description"
                                    :error-messages="descriptionErrors"
                                    @input="$v.form.description.$touch()"
                                    @blur="$v.form.description.$touch()"
                                >
                                </v-textarea>
                            </v-col>
                        </v-row>
                        <v-row class=" ">
                            <v-col
                                class="mt-n6"
                                cols="12"
                                sm="4"
                            >
                                <v-text-field
                                   
                                    color="grey "
                                    v-model="form.new_price"
                                    label="Price"
                                    type="number"
                                    outlined
                                    filled
                                    :error-messages="newPriceErrors"
                                    @input="$v.form.new_price.$touch()"
                                    @blur="$v.form.new_price.$touch()"
                                    
                                >
                                </v-text-field>   
                            </v-col>
                            <v-col
                                class="mt-n6"
                                cols="12"
                                sm="4"
                            >
                                <v-select
                                    :items="categoriesData"
                                    item-text="name"
                                    item-value="id"
                                    label="select category"
                                    outlined
                                    filled
                                    v-model="form.category_id"
                                    :error-messages="categoryErrors"
                                    @input="$v.form.category_id.$touch()"
                                    @blur="$v.form.category_id.$touch()"
                                >
                                </v-select>    
                            </v-col>
                            <v-col
                                class="mt-n6"
                                cols="12"
                                sm="4"
                            >
                                <v-select
                                    :items="conditions"
                                    item-text="name"
                                    item-value="id"
                                    label="condition"
                                    outlined
                                    filled
                                    v-model="form.condition_type"
                                    :error-messages="conditionErrors"
                                    @input="$v.form.condition_type.$touch()"
                                    @blur="$v.form.condition_type.$touch()"
                                >
                                </v-select>    
                            </v-col>
                        </v-row>
                        <v-row class="">
                            <v-col
                                class="mt-n6"
                                cols="12"
                                sm="4"
                            >
                                <v-select
                                    :items="PropertyType"
                                    item-text="name"
                                    item-value="value"
                                    label="select property type"
                                    outlined
                                    v-model="form.product_type"
                                    filled
                                    :error-messages="propertyTypeErrors"
                                    @input="$v.form.product_type.$touch()"
                                    @blur="$v.form.product_type.$touch()"
                                >
                                </v-select>
                            </v-col>
                            <v-col
                                v-show="form.product_type == 'For Short Let' || form.product_type == 'For Rent'"
                                class="mt-n6"
                                cols="12"
                                sm="4"
                            >
                                <v-select
                                    
                                    :items="duration"
                                    item-text="name"
                                    item-value="value"
                                    label="select duration"
                                    outlined
                                    filled
                                    v-model="form.duration"
                                >
                                </v-select>
                            </v-col>
                             <v-col
                                class="mt-n6"
                                cols="12"
                                sm="4"
                            >
                                <v-select
                                    :items="listingIn"
                                    item-text="name"
                                    item-value="value"
                                    label="Listing In"
                                    v-model="form.listed_in"
                                    :error-messages="listedErrors"
                                    @input="$v.form.listed_in.$touch()"
                                    @blur="$v.form.listed_in.$touch()"
                                    outlined
                                    filled
                                >
                                </v-select>
                            </v-col>
                        </v-row>
                        <h4 class="mb-2">Property Location</h4>
                        <v-row class="">
                                <v-col
                                cols="12"
                            >
                                <v-text-field
                                    color="grey ma-16"
                                    v-model="form.address"
                                    label="Property Address"
                                    outlined
                                    filled
                                    :error-messages="addressErrors"
                                    @input="$v.form.address.$touch()"
                                    @blur="$v.form.address.$touch()"
                                    
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="mt-n8">
                            <v-col
                                cols="6"
                                sm="6"
                            >
                                 <v-select
                                    :items="states"
                                    item-text="name"
                                    item-value="id"
                                    v-on:change="onChange"
                                    label="select state"
                                    v-model="form.state_id"
                                    outlined
                                    filled
                                    :error-messages="stateErrors"
                                    @input="$v.form.state_id.$touch()"
                                    @blur="$v.form.state_id.$touch()"
                                >
                                </v-select>
                            </v-col>
                             <v-col
                                cols="6"
                                sm="6"
                            >
                                <v-select
                                    :items="lgas"
                                    label="select lga"
                                    item-text="name"
                                    item-value="id"
                                    outlined
                                    filled
                                    v-model="form.lga_id"
                                    :error-messages="lgaErrors"
                                    @input="$v.form.lga_id.$touch()"
                                    @blur="$v.form.lga_id.$touch()"
                                >
                                </v-select>
                            </v-col>
                        </v-row>
                         <div>
                            <h4 class="mb-8">Property Details</h4>
                            <v-row>
                                <v-col class="mt-n6" >
                                    <v-text-field
                                        color="grey ma-16 "
                                        v-model="form.developer"
                    
                                        label="name of developer/owner"
                                        outlined
                                        filled   
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col class="mt-n6">
                                    <v-text-field
                                        color="grey ma-16 "
                                        v-model="form.developer_number"
                    
                                        label="developer phone number"
                                        outlined
                                        filled   
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row>
                                   <v-col
                                    class="mt-n6"
                                    cols="6"
                                    
                                >
                                    <v-text-field
                                        color="grey ma-16 "
                                        v-model="form.size"
                                        label="Size in m2"
                                        outlined
                                        type="number"
                                        filled
                                  
                                        
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col
                                    class="mt-n6"
                                    cols="6"
                                   
                                >
                                    <v-text-field
                                        color="grey ma-16"
                                        v-model="form.lot_size"
                                        label="Lot Size in m2"
                                        outlined
                                        type="number"
                                        filled
                                
                                        
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <v-row class="mt-n2"  v-if="form.listed_in != 'land' && form.listed_in != '' ">
                                <v-col
                                    class="mt-n6"
                                    cols="6"
                                    md="4"
                                >
                                    <v-text-field
                                        color="grey ma-16"
                                        v-model="form.living_room"
                                        label="Living Room"
                                        outlined
                                        type="number"
                                        filled
                                    
                                        
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col
                                    class="mt-n6"
                                    cols="6"
                                    md="4"
                                >
                                    <v-text-field
                                        color="grey ma-16"
                                        v-model="form.garage"
                                        label="Garages"
                                        outlined
                                        type="number"
                                        filled
                                     
                                        
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col
                                    class="mt-n6"
                                    cols="12"
                                    md="4"
                                >
                                    <v-text-field
                                        color="grey ma-16"
                                        v-model="form.bathroom"
                                        label="Bathroom"
                                        outlined
                                        type="number"
                                        filled
                                     
                                        
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col
                                    class="mt-n6"
                                    cols="12"
                                    sm="6"
                                >
                                    <v-text-field
                                        color="grey ma-16"
                                        v-model="form.available_from"
                                        label="Availability From"
                                        outlined
                                        type="date"
                                        filled
                                  
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col
                                    class="mt-n6"
                                    cols="12"
                                    sm="6"
                                >
                                    <v-text-field
                                        color="grey ma-16"
                                        v-model="form.floor_num"
                                        label="Floor Number"
                                        outlined
                                        type="number"
                                        filled
                                       
                                        
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>
                            <div  v-if="form.listed_in != 'land' && form.listed_in != '' ">
                                <h4 class="mb-10">Amenities and Features</h4>
                                <v-row class="mt-n2">
                                    <v-col
                                        v-for="feature in form.features"
                                        v-bind:key="feature.name"
                                        cols="6"
                                        sm="4"
                                        md="3"
                                    >
                                        <v-checkbox
                                            class="mt-n8"
                                            v-model="feature.value"
                                            :label="feature.name"
                                            color="black"
                                        >
                                        </v-checkbox>
                                    </v-col>
                                </v-row>
                            </div>
                        </div>
                        <v-row class="mt-n2">
                            <v-col
                                cols="12"
                            >
                                <div class="float-right d-none d-md-block">
                                    <v-btn 
                                        v-show="loading == false"
                                        @click="submit"
                                        small
                                        class="py-4 mb-4 lime darken-4 ma-2 white--text"
                                    >
                                        Update Property
                                    </v-btn>
                                    <v-progress-circular
                                        v-show="loading"
                                        :width="3"
                                        class="mb-4"
                                        color="green"
                                        indeterminate
                                    >
                                    </v-progress-circular>
                                </div>
                            </v-col>
                        </v-row>
                          
                    </v-container>
                </v-form>
            </v-col>
            <v-col class="col-12 col-md-3 grey lighten-3 ">
                <div class="container d-none d-lg-block"> 
                    <div class="container d-none d-md-block ml-16 mt-2"> 
                        <Notification/>	
                    </div>
                </div>
                <v-container class=" white mt-n6" v-show="skeLoader == false">
                    <div class="text-center mt-4">
                        <h4> Property Image</h4>
                        <div id="app">
                            <vue-dropzone 
                                id="drop1"   
                                ref="myVueDropzone" 
                                :options="dropOptions"
                                @vdropzone-error="dropzoneError"
                                @vdropzone-success="afterSuccess"
                                @vdropzone-queue-complete="afterComplete"
                                @vdropzone-sending="sendingEvent"
                            >
                            </vue-dropzone>
                            <span v-show="imageError" class="red--text">image is required</span>
                        </div>
                        <div class="text-subtitle-2">Maximum size 2mb and maximum of 4 images</div>
                    </div>
                    <div class="mt-2 d-md-none">
                        <v-btn
                            v-show="loading == false"
                            @click="submit"
                            small
                            class="mt-2 lime darken-4 white--text"		
                            >
                            Update Property
                        </v-btn>
                        <v-progress-circular
                            v-show="loading"
                            :width="3"
                            class="mb-4"
                            color="green"
                            indeterminate
                        >
                        </v-progress-circular>
                    </div>
                </v-container>
                <v-row class="mt-6 px-1">
                    <v-col class="" v-for="image in form.product_images" md="12" lg="6" v-bind:key="image.id">
                        <div >
                            <img width="150px" height="100px" :src="image.full">
                            <div>
                                <v-icon @click="deleteImage(image.id)"  class="red--text ">mdi-delete</v-icon>
                                <v-icon  @click="view(image.full)"  class=" ">mdi-eye</v-icon>
                            </div>
                        </div>
                        <v-dialog 
                            v-model="dialogDelete" 
                            max-width="500px"
                            persistent 
                            :retain-focus="false"
                        >
                            <v-card>
                                <v-card-title class="text-h5">Are you sure you want to delete this image?</v-card-title>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn class="white--text" color="grey darken-3"  @click="closeDelete">Cancel</v-btn>
                                    <v-btn v-show="loading2 == false"  class="white--text" color="red lighten-2"  @click="deleteItemConfirm">OK</v-btn>
                                    <v-progress-circular
                                        v-show="loading2"
                                        :width="3"
                                        class="ml-4"
                                        color="green"
                                        indeterminate
                                    >
                                    </v-progress-circular>
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                        <v-dialog 
                            v-model="dialogView" 
                            max-width="500px"
                            persistent 
                            :retain-focus="false"
                        >
                            <v-card>
                                <v-img
                                    :src="image_url"
                                    aspect-ratio="1.7"
                                >
                                </v-img>
                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn class="white--text" color="grey darken-3"  @click="closeView">Close</v-btn>
                                    <v-spacer></v-spacer>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>

<script>

import SkeletonLoader from '../layout/SkeletonLoader';
import Notification from '../../components/auth/NotificationWrapper';
import { validationMixin } from 'vuelidate'
import { mapActions, mapState } from "vuex";
import { required} from 'vuelidate/lib/validators'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import vueDropzone from "vue2-dropzone";
import UserSideBar from '../../components/auth/UserSideBar';

export default {
    components: {UserSideBar, vueDropzone, Notification, SkeletonLoader},
    mixins: [validationMixin],
    validations: {
        form: {
            title: { required},
            description: { required},
            new_price: { required},
            category_id: { required},
            product_type: { required},
            state_id: { required},
            lga_id: { required},
            address: { required},
            listed_in: { required},
            condition_type: {required},
        }
    },
    metaInfo() {
		return {
			title: `Update Property - Housefinder`,
			
		}
	},
    data: () => ({
        images: [],
        upload: false,
        skeLoader: false,
        loading: false,
        imageError: false,
        dialogDelete: false,
        dialogView: false,
        image_url: '',
        product_id: '',
        PropertyType: [
            {name: 'For Sale', value: 'For Sale'},
            {name: 'For Rent', value: 'For Rent'},
            {name: 'For Short Let', value: 'For Short Let'}
        ],
        conditions: [
            {name: 'Newly Build', value: 'Newly Build'},
            {name: 'Fairly Used', value: 'Fairly Used'},
            {name: 'Old', value: 'Old'},
            {name: 'Carcass', value: 'Carcass'},
            {name: 'Under Construction', value: 'Under Construction'},
        ],
        duration: [
            {name: 'per day', value:'per day'},
            {name: 'per week', value:'per week'},
            {name: 'per month', value:'per month'},
            {name: 'per half-year', value:'per half-year'},
            {name: 'per annum', value:'per annum'}
        ],
        imageId: '',
        form: {},
        loading2: false,
        dropOptions: {
            url: `${process.env.VUE_APP_BASEURL}/api/admin/product/upload`,
            autoProcessQueue: false,
            paramName: "image",
            addRemoveLinks: true,
            maxFilesize: 3,
            parallelUploads: 1,
            uploadMultiple: false,
            maxFiles: 6,
            thumbnailWidth: 100, // px
            thumbnailHeight: 100,
            headers: "",
          
        },
        gender: ['male', 'female'],
        listingIn: [
            {name: 'Land', value: 'land'},
            {name: 'Detached', value: 'detached'},
            {name: 'Semi Detached', value: 'semi detached'},
            {name: 'Furnished', value: 'funished'},
            {name: 'Terrace', value: 'terrace'},
            {name: 'Stand Alone', value: 'stand alone'},
            {name: 'Serviced', value: 'serviced'},
        ],
    }),
    computed:{
        ...mapState(["countries", 'states','lgas', 'categoriesData']),
        titleErrors () {
            const errors = []
            if (!this.$v.form.title.$dirty) return errors
            !this.$v.form.title.required && errors.push('title is required.')
            return errors
        },
        descriptionErrors () {
            const errors = []
            if (!this.$v.form.description.$dirty) return errors
            !this.$v.form.description.required && errors.push(' description is required.')
            return errors
        },
        durationErrors () {
            const errors = []
            if (!this.$v.form.duration.$dirty) return errors
            !this.$v.form.duration.required && errors.push('duration is required.')
            return errors
        },
        newPriceErrors () {
            const errors = []
            if (!this.$v.form.new_price.$dirty) return errors
            !this.$v.form.new_price.required && errors.push(' new price is required.')
            return errors
        },
        categoryErrors () {
            const errors = []
            if (!this.$v.form.category_id.$dirty) return errors
            !this.$v.form.category_id.required && errors.push('category is required.')
            return errors
        },
        propertyTypeErrors () {
            const errors = []
            if (!this.$v.form.product_type.$dirty) return errors
            !this.$v.form.product_type.required && errors.push('property type in is required.')
            return errors
        },
        addressErrors () {
            const errors = []
            if (!this.$v.form.address.$dirty) return errors
            !this.$v.form.address.required && errors.push('address is required.')
            return errors
        },
        stateErrors () {
            const errors = []
            if (!this.$v.form.state_id.$dirty) return errors
            !this.$v.form.state_id.required && errors.push('state is required.')
            return errors
        },
        lgaErrors () {
            const errors = []
            if (!this.$v.form.lga_id.$dirty) return errors
            !this.$v.form.lga_id.required && errors.push('lga is required.')
            return errors
        },
        listedErrors () {
            const errors = []
            if (!this.$v.form.listed_in.$dirty) return errors
            !this.$v.form.listed_in.required && errors.push('Listed_In is required.')
            return errors
        },
        conditionErrors () {
            const errors = []
            if (!this.$v.form.condition_type.$dirty) return errors
            !this.$v.form.condition_type.required && errors.push('condition is required.')
            return errors
        },
    },
    methods: {
        ...mapActions(["getLocation", "getLga", "updateProduct", "getCategories",
                    "findUserProduct", "markNotify", "productImageDelete"]),
        async submit() {
            this.$v.$touch()
            if (this.$v.$invalid) {
                this.$toastr.e("Some Fields Are Missing ");
            }
            else {
                this.loading = true;
                let result = await this.updateProduct(this.form);
                if(result.success == true){
                    console.log(result.success)
                    if(this.$refs.myVueDropzone.getQueuedFiles().length > 0){
                        this.product_id = this.form.id;
                        this.$refs.myVueDropzone.processQueue()
                    }
                    else{
                        this.$toastr.s("Product Updated Successfully");
                        this.loading = false;
                        this.$router.push(`/user/my-products`);
                    }
                
                }
            }
        },
        async deleteItemConfirm(){
            this.loading2 = true;
            let data = {image_id: this.imageId};
            let response = await this.productImageDelete(data);
            if(response.success){
                    this.$toastr.s("Image Deleted Successfully");
                    this.loading2 = false;
                    let index = this.form.product_images.findIndex(p => p.id == this.imageId);
                    this.form.product_images.splice(index, 1);
           }
           else{
                this.$toastr.e(response);
                this.loading2 = false;
           }
            this.closeDelete()
        },
        onChange(event) {
            this.getLga(event);
        },
        afterComplete() {
            this.$refs.myVueDropzone.removeAllFiles();
            this.loading = false;
            this.$router.push(`/user/my-products`);
       },
        async afterSuccess(file, response) {
        console.log(file)
        if(response.success == true) {
            console.log(response)
            //this.form.image = response.data.image;
            this.$toastr.s("Product Updated Successfully");
            this.loading = false;
            await this.findUserProduct(this.$route.params.slug)
            this.form = this.editedProduct.data;
        }
        else{
            this.$toastr.e(response.message)
        }
       },
       dropzoneError(file, error, xhr){
            this.$toastr.e(error);
            console.log(file)
            console.log(xhr)
            this.loading = false;
       },
       sendingEvent(file, xhr, formData){
           formData.append('product_id', this.product_id);
       },
       async getData(slug){
            this.skeLoader = true;
            let data = await this.findUserProduct(slug)
            this.form = data.data;
            await this.getCategories();
            await this.getLocation();
            await  this.onChange(this.form.state_id);
            this.markNotify(this.$route.query.notify_id);
            this.skeLoader = false;
       },
        view(image) {
            this.image_url = image;
            this.dialogView = true
        },
         deleteImage(id) {
            this.imageId = id;
            this.dialogDelete = true
        },
        closeDelete () {
            this.dialogDelete = false
        },
        closeView () {
            this.dialogView = false
        },

    },
    watch: { 
		$route(to, from) { 
			if(to.params.slug !== from.params.slug){
                this.getData(this.$route.params.slug);
				//window.scrollTo(0,0);
				
			} 
		},
        dialogDelete (val) {
            val || this.closeDelete()
        }, 
	},
    created() {
        
        this.getData(this.$route.params.slug);
        let token = localStorage.getItem('token');
        let bearer = {"Authorization": `Bearer ${token}`}
        this.dropOptions.headers = bearer;
        window.scrollTo(0,0);
       
    },   
}
</script>