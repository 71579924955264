<template>
  <div>
    <v-responsive
     
      class="mx-auto mb-4"
    >
    </v-responsive>

    <v-card
      style="max-height: 290px"
      flat
      class="mx-auto overflow-y-auto"
      
    >
      <v-list-item  
        v-for="item in notify.notify" 
        v-bind:key="item.id"  
        style="border-bottom: 1px solid #E0E0E0"
      >
        
        <v-list-item-content class="" :class="item.read_at ? '' : 'font-weight-medium' " >
          
          <div  class="py-1"  @click="switchUrl(item, $event)">
            <a class="text-decoration-none black--text" href="#">{{ item.data.message }}</a>
          </div>
        </v-list-item-content>

        <v-list-item-action class="">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
                <v-icon
                
                v-bind="attrs"
                v-on="on"
                >
                mdi-dots-vertical
                </v-icon>
            </template>
            <v-list>
                <v-list-item
                @click="removeNotify(item.id)"
                >
                <v-list-item-title>Delete</v-list-item-title>
                </v-list-item>
            </v-list>
          </v-menu>
        </v-list-item-action>
      </v-list-item>
      <div  
        v-show="notify.notify.length == 0"
        class="text-center mt-6">
      
        Notification is empty
      </div>

   
    </v-card>
  </div>
</template>

<script>
 import {mapState, mapActions} from "vuex";
  export default {
    data: () => ({
      benched: 0,
    }),
    computed: {
      ...mapState({notify: state => state.auth.userNotify}),
      length () {
        return 7000
      },
    },
    methods: {
      ...mapActions(["deleteNotify", "markNotify"]),
      async removeNotify(id){
        await this.deleteNotify(id)
      },
      readNotify(id){
        this.markNotify(id);
      },
      switchUrl(item, event){
        event.preventDefault();
        if(item.data.product_slug){
          this.$router.push(`/product/edit/${item.data.product_slug}?notify_id=${item.id}`)
        }
        else{
          this.markNotify(item.id);
        }
      }
    }
  }
</script>

