<template>
  <div class="mt-16 text-center">
    <paystack
      :amount="amount * 100"
      :email="email"
      :paystackkey="public_key"
      :reference="reference"
      :callback="updateOrder"
      :close="close"
    >
      <v-btn class="green white--text"
        >Make Payment<v-icon>mdi-arrow-right-circle</v-icon></v-btn
      >
    </paystack>
  </div>
</template>

<script>
import paystack from "vue-paystack";
import { mapActions } from "vuex";
export default {
  components: {
    paystack,
  },
  data: () => {
    return {
      amount: 200,
      full_name: null,
      email: "anochienwabueze@gmail.com",
      public_key: "pk_test_81850789e26854a71b2c19d4fd0c88d84302eea5",
      order_number: "",
      meta: {
        order_number: "testing",
      },
    };
  },
  computed: {
    reference() {
      let text = "";
      let possible =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (let i = 0; i < 10; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));
      return text;
    },
  },
  methods: {
    ...mapActions(["checkout", "updateOrder"]),
    close: () => {
      console.log("You closed checkout page");
    },
    async generateOrder() {
      let packageId = { package_id: 5 };
      let response = await this.checkout(packageId);
      console.log(response.data.order_number);
      localStorage.setItem("order_number", response.data.order_number);
    },
  },
  created() {
    this.generateOrder();
  },
};
</script>
