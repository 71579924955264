<template>
  <div class="mb-n10">
    <v-row>
      <v-col class="col-12 col-sm-3 col-md-3 col-lg-2 d-none d-md-block">
        <User-Side-Bar />
      </v-col>
      <v-col class="col-md-9 col-lg-10 grey lighten-3 px-2 pl-md-4">
        <div v-if="skeloader">
          <skeleton-loader />
        </div>
        <div v-if="skeloader == false">
          <div :class="{ 'pr-8': $vuetify.breakpoint.smAndDown }">
            <h3 class="mb-6 mt-2 grey--text text--darken-4 text-md-h6">
              <router-link
                class="black--text text-decoration-none"
                :to="`/dashboard`"
              >
                <v-icon class="mt-n1 mr-2 hidden-md-and-up"
                  >mdi-arrow-left</v-icon
                >
              </router-link>
              Verification
            </h3>
            <div class="float-right mt-n8 mr-md-6">
              <Notification />
            </div>
          </div>
          <div class="white px-2 mt-10 mb-4">
            <v-row>
              <v-col cols="12" md="7">
                <div v-if="userVerifyStatus.status !== '' " class="mb-3 px-2">
                  Verification Status:
                  <span
                    class="font-weight-bold"
                    :class="[
                      userVerifyStatus.status == 0
                        ? ''
                        : userVerifyStatus.status == 1
                        ? 'green--text'
                        : 'red--text',
                    ]"
                  >
                    {{
                      userVerifyStatus.status == 0
                        ? "Pending"
                        : userVerifyStatus.status == 1
                        ? "Verified"
                        : "Declined"
                    }}
                  </span>
                </div>
                <div v-else class="mb-3 px-2">
                  Verification Status:
                  <span class="font-weight-bold">
                    Not Verified
                  </span>
                </div>
                <v-alert v-show="success" type="success">
                  Documents Submited Successfully
                </v-alert>
                <v-form ref="form" v-show="userVerifyStatus.status != 1">
                  <v-container>
                    <v-row class="mt-n2">
                      <v-col cols="12">
                        <v-text-field
                          color="grey ma-16"
                          v-model="form.company_name"
                          label="Company Name"
                          :rules="[rules.required]"
                          outlined
                          filled
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-text-field
                          color="grey ma-16"
                          v-model="form.company_number"
                          label="Company Number"
                          outlined
                          :rules="[rules.required]"
                          filled
                        >
                        </v-text-field>
                      </v-col>
                      <v-col cols="12">
                        <v-select
                          :items="idType"
                          item-text="name"
                          item-value="value"
                          label="Select Id type"
                          outlined
                          :rules="[rules.required]"
                          filled
                          v-model="form.id_card_type"
                        >
                        </v-select>
                      </v-col>
                      <v-col cols="12">
                        <v-file-input
                          label="ID Card"
                          outlined
                          :rules="[rules.required]"
                          filled
                          v-model="form.id_card"
                        >
                        </v-file-input>
                      </v-col>
                      <v-col cols="12">
                        <div class="text-subtitle-1">* Utility Bill</div>
                        <v-file-input
                          label="Proof of Address"
                          outlined
                          filled
                          :rules="[rules.required]"
                          v-model="form.address"
                        >
                        </v-file-input>
                        <div class="float-right">
                          <v-btn
                            v-show="loading == false"
                            @click="updateKyc"
                            color="#0062df"
                            class="mb-4 darken-4 ma-2 white--text"
                          >
                            Update
                          </v-btn>
                          <v-progress-circular
                            v-show="loading"
                            :width="3"
                            class="mb-4"
                            color="green"
                            indeterminate
                          >
                          </v-progress-circular>
                        </div>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import Notification from "../../components/auth/NotificationWrapper";
import UserSideBar from "../../components/auth/UserSideBar";
import SkeletonLoader from "../layout/SkeletonLoader";
export default {
  components: { UserSideBar, Notification, SkeletonLoader },
  metaInfo() {
    return {
      title: `Request Verifation - Housefinder`,
        
    }
  },
  data() {
    return {
      skeloader: true,
      loading: false,
      UserId: "",
      userVerifyStatus: {},
      success: false,
      rules: {
        required: value => !!value || "Required.",
      },
      form: {
        address: "",
        id_card: "",
        id_card_type: "",
        company_number: "",
        company_name: "",
      },
      idType: [
        { name: "National Id", value: "National Id" },
        { name: "Voter's Card", value: "Voter's Card" },
        { name: "Driver's License", value: "Driver's License" },
        { name: "International Passport", value: "International Passport" },
      ],
    };
  },
  computed: {
    ...mapState({ singleVerifyUser: state => state.auth.singleVerifyUser }),
  },
  methods: {
    ...mapActions(["requestVerification", "getSingleVerifyUser"]),
    async updateKyc() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let formData = new FormData();
        formData.append("address", this.form.address);
        formData.append("id_card", this.form.id_card);
        formData.append("id_card_type", this.form.id_card_type);
        formData.append("company_number", this.form.company_number);
        formData.append("company_name", this.form.company_name);

        console.log(formData);
        let response = await this.requestVerification(formData);
        if (response.success) {
          this.userVerifyStatus = response.data;
          this.success = true;
          this.$toastr.s("Sent Successfully");
          this.loading = false;
          this.$refs.form.resetValidation();
          this.form = {
            address: "",
            id_card: "",
            id_card_type: "",
            company_number: "",
            company_name: "",
          };
        } else {
          this.$toastr.e("An error Occurr");
          this.loading = false;
        }
      }
    },
    async getData() {
      this.skeloader = true;
      await this.getSingleVerifyUser();
      if(this.singleVerifyUser){
        this.userVerifyStatus = this.singleVerifyUser;
      }
      else{
        this.userVerifyStatus.status = ''
      }
      this.skeloader = false;
    },
  },
  created() {
    let authUser = localStorage.getItem("authUser");
    let user = JSON.parse(authUser);
    this.UserId = user.id;
    this.getData();
  },
};
</script>
