<template>
  <div>
    <v-row>
      <v-col class="col-12 col-sm-3 col-md-3 col-lg-2 d-none d-md-block">
        <User-Side-Bar />
      </v-col>
      <v-col class="col-md-6 col-lg-7 grey lighten-3 px-2 pl-md-4">
        <div :class="{ 'pr-8': $vuetify.breakpoint.smAndDown }">
          <h3 class="mb-6 mt-2 grey--text text--darken-4 text-md-h6">
            <router-link
              class="black--text text-decoration-none"
              :to="`/dashboard`"
            >
              <v-icon class="mt-n1 mr-2 hidden-md-and-up"
                >mdi-arrow-left</v-icon
              >
            </router-link>
            Add Property
          </h3>
          <div class="float-right mt-n8  d-md-none">
            <Notification />
          </div>
        </div>
        <v-form class="white px-md-2">
          <v-container>
            <h4 class="mb-2">Property Description</h4>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  color="grey"
                  v-model="form.title"
                  label="Title"
                  outlined
                  filled
                  :error-messages="titleErrors"
                  @input="$v.form.title.$touch()"
                  @blur="$v.form.title.$touch()"
                >
                </v-text-field>
              </v-col>
              <v-col class="mt-n8" cols="12">
                <v-textarea
                  outlined
                  v-model="form.description"
                  name="input-7-4"
                  filled
                  label="Description"
                  :error-messages="descriptionErrors"
                  @input="$v.form.description.$touch()"
                  @blur="$v.form.description.$touch()"
                >
                </v-textarea>
              </v-col>
            </v-row>
            <v-row class=" ">
              <v-col class="mt-n6" cols="12" sm="4">
                <v-text-field
                  color="grey "
                  v-model="form.new_price"
                  label="Price"
                  type="number"
                  outlined
                  filled
                  :error-messages="newPriceErrors"
                  @input="$v.form.new_price.$touch()"
                  @blur="$v.form.new_price.$touch()"
                >
                </v-text-field>
              </v-col>
              <v-col class="mt-n6" cols="12" sm="4">
                <v-select
                  :items="categoriesData"
                  item-text="name"
                  item-value="id"
                  label="select category"
                  outlined
                  filled
                  v-model="form.category_id"
                  :error-messages="categoryErrors"
                  @input="$v.form.category_id.$touch()"
                  @blur="$v.form.category_id.$touch()"
                >
                </v-select>
              </v-col>
              <v-col class="mt-n6" cols="12" sm="4">
                <v-select
                  :items="conditions"
                  item-text="name"
                  item-value="id"
                  label="condition"
                  outlined
                  filled
                  v-model="form.condition_type"
                  :error-messages="conditionErrors"
                  @input="$v.form.condition_type.$touch()"
                  @blur="$v.form.condition_type.$touch()"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row class="">
              <v-col class="mt-n6" cols="12" sm="4">
                <v-select
                  :items="PropertyType"
                  item-text="name"
                  item-value="value"
                  label="select property type"
                  outlined
                  v-model="form.product_type"
                  filled
                  :error-messages="propertyTypeErrors"
                  @input="$v.form.product_type.$touch()"
                  @blur="$v.form.product_type.$touch()"
                >
                </v-select>
              </v-col>
              <v-col
                v-show="
                  form.product_type == 'For Short Let' ||
                    form.product_type == 'For Rent'
                "
                class="mt-n6"
                cols="12"
                sm="4"
              >
                <v-select
                  :items="duration"
                  item-text="name"
                  item-value="value"
                  label="select duration"
                  outlined
                  filled
                  v-model="form.duration"
                >
                </v-select>
              </v-col>
              <v-col class="mt-n6" cols="12" sm="4">
                <v-select
                  :items="listingIn"
                  item-text="name"
                  item-value="value"
                  label="Listing In"
                  v-model="form.listed_in"
                  :error-messages="listedErrors"
                  @input="$v.form.listed_in.$touch()"
                  @blur="$v.form.listed_in.$touch()"
                  outlined
                  filled
                >
                </v-select>
              </v-col>
              <v-col class="mt-n6"  cols="12" sm="4">
                  <v-text-field
                    color="grey ma-16 "
                    v-model="form.developer"
                    label="name of developer/owner"
                    outlined
                    filled
                  >
                  </v-text-field>
                </v-col>
                <v-col class="mt-n6" cols="12" sm="4">
                  <v-text-field
                    color="grey ma-16 "
                    v-model="form.developer_number"

                    label="developer phone number"
                    outlined
                    filled   
                  >
                  </v-text-field>
                </v-col>
            </v-row>
            <h4 class="mb-2">Property Location</h4>
            <v-row class="">
              <v-col cols="12">
                <v-text-field
                  color="grey ma-16"
                  v-model="form.address"
                  label="Property Address"
                  outlined
                  filled
                  :error-messages="addressErrors"
                  @input="$v.form.address.$touch()"
                  @blur="$v.form.address.$touch()"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-n8">
              <v-col cols="6" sm="6">
                <v-select
                  :items="states"
                  item-text="name"
                  item-value="id"
                  v-on:change="onChange"
                  label="select state"
                  v-model="form.state_id"
                  outlined
                  filled
                  :error-messages="stateErrors"
                  @input="$v.form.state_id.$touch()"
                  @blur="$v.form.state_id.$touch()"
                >
                </v-select>
              </v-col>
              <v-col cols="6" sm="6">
                <v-select
                  :items="lgas"
                  label="select lga"
                  item-text="name"
                  item-value="id"
                  outlined
                  filled
                  v-model="form.lga_id"
                  :error-messages="lgaErrors"
                  @input="$v.form.lga_id.$touch()"
                  @blur="$v.form.lga_id.$touch()"
                >
                </v-select>
              </v-col>
            </v-row>
            <div>
              <h4 class="mb-8">Property Details</h4>
              <v-row>
                <v-col class="mt-n6" cols="6">
                  <v-text-field
                    color="grey ma-16 "
                    v-model="form.size"
                    label="Size in m2"
                    outlined
                    type="number"
                    filled
                  >
                  </v-text-field>
                </v-col>
                <v-col class="mt-n6" cols="6">
                  <v-text-field
                    color="grey ma-16"
                    v-model="form.lot_size"
                    label="Lot Size in m2"
                    outlined
                    type="number"
                    filled
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <v-row
                class="mt-n2"
                v-if="form.listed_in != 'land' && form.listed_in != ''"
              >
                <v-col class="mt-n6" cols="6" md="4">
                  <v-text-field
                    color="grey ma-16"
                    v-model="form.living_room"
                    label="Living Room"
                    outlined
                    type="number"
                    filled
                  >
                  </v-text-field>
                </v-col>
                <v-col class="mt-n6" cols="6" md="4">
                  <v-text-field
                    color="grey ma-16"
                    v-model="form.bedroom"
                    label="Bedroom"
                    outlined
                    type="number"
                    filled
                  >
                  </v-text-field>
                </v-col>
                <v-col class="mt-n6" cols="6" md="4">
                  <v-text-field
                    color="grey ma-16"
                    v-model="form.bathroom"
                    label="Bathroom"
                    outlined
                    type="number"
                    filled
                  >
                  </v-text-field>
                </v-col>
                  <v-col class="mt-n6" cols="6" sm="6" md="4">
                  <v-text-field
                    color="grey ma-16"
                    v-model="form.garage"
                    label="Garage"
                    outlined
                    type="number"
                    filled
                  >
                  </v-text-field>
                </v-col>
                <v-col class="mt-n6" cols="6" sm="6" md="4">
                  <v-text-field
                    color="grey ma-16"
                    v-model="form.available_from"
                    label="Availability From"
                    outlined
                    type="date"
                    filled
                  >
                  </v-text-field>
                </v-col>
                <v-col class="mt-n6" cols="6" sm="6" md="4">
                  <v-text-field
                    color="grey ma-16"
                    v-model="form.floor_num"
                    label="Floor Number"
                    outlined
                    type="number"
                    filled
                  >
                  </v-text-field>
                </v-col>
              </v-row>
              <div v-if="form.listed_in != 'land' && form.listed_in != ''">
                <h4 class="mb-10">Amenities and Features</h4>
                <v-row class="mt-n2">
                  <v-col
                    v-for="feature in form.features"
                    v-bind:key="feature.name"
                    cols="6"
                    sm="4"
                    md="3"
                  >
                    <v-checkbox
                      class="mt-n8"
                      v-model="feature.value"
                      :label="feature.name"
                      color="black"
                    >
                    </v-checkbox>
                  </v-col>
                </v-row>
              </div>
            </div>
            <v-row class="mt-n2">
              <v-col cols="12">
                <div class="float-right d-none d-md-block">
                  <v-btn
                    v-show="loading == false"
                    @click="submit"
                    small
                    class="py-4 mb-4 lime darken-4 ma-2 white--text"
                  >
                    Add Property
                  </v-btn>
                  <v-progress-circular
                    v-show="loading"
                    :width="3"
                    class="mb-4"
                    color="green"
                    indeterminate
                  >
                  </v-progress-circular>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-col>
      <v-col class="col-12 col-md-3 grey lighten-3 ">
        <div class="container d-none d-lg-block">
          <div class="container d-none d-md-block ml-16 mt-2">
            <Notification />
          </div>
        </div>
        <v-container class="white mt-n16 mt-md-14 mt-lg-n6">
          <div class="text-center mt-3">
            <h4>Property Image</h4>
            <div id="app">
              <div>
                <vue-dropzone
                  id="drop1"
                  ref="myVueDropzone"
                  :options="dropOptions"
                  @vdropzone-error="dropzoneError"
                  @vdropzone-queue-complete="afterComplete"
                  @vdropzone-sending="sendingEvent"
                >
                </vue-dropzone>
              </div>
              <span v-show="imageError" class="red--text"
                >minimum of 3 images is required</span
              >
            </div>
            <div class="text-subtitle-2">
              Max size 2mb, min of 3 images and max of 4 images
            </div>
          </div>
          <div class="mt-2 d-md-none">
            <v-btn
              v-show="loading == false"
              @click="submit"
              small
              color="#0062df"
              class="px-1 mt-2 darken-4 white--text"
            >
              Add Property
            </v-btn>
            <v-progress-circular
              v-show="loading"
              :width="3"
              class="mb-4"
              color="green"
              indeterminate
            >
            </v-progress-circular>
          </div>
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Notification from "../../components/auth/NotificationWrapper";
import { validationMixin } from "vuelidate";
import { mapActions, mapState } from "vuex";
import { required } from "vuelidate/lib/validators";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import vueDropzone from "vue2-dropzone";
import UserSideBar from "../../components/auth/UserSideBar";
export default {
  components: { UserSideBar, vueDropzone, Notification },
  mixins: [validationMixin],
  validations: {
    form: {
      title: { required },
      description: { required },
      new_price: { required },
      category_id: { required },
      product_type: { required },
      state_id: { required },
      lga_id: { required },
      address: { required },
      listed_in: { required },
      condition_type: { required },
    },
  },
  metaInfo() {
		return {
			title: `Add Property - Housefinder.com.ng`,
			
		}
	},
  data: () => ({
      loading: false,
      user: {},
      imageError: false,
      product_id: '',
      PropertyType: [
          {name: 'For Sale', value: 'For Sale'},
          {name: 'For Rent', value: 'For Rent'},
          {name: 'For Short Let', value: 'For Short Let'}
      ],
      duration: [
          {name: 'per day', value:'per day'},
          {name: 'per week', value:'per week'},
          {name: 'per month', value:'per month'},
          {name: 'per half-year', value:'per half-year'},
          {name: 'per annum', value:'per annum'}
      ],
      form: {
          title: '',
          description: '',
          new_price: '',
          category_id: '',
          product_type: '',
          nationality_id: '',
          state_id: '',
          lga_id: '',
          address: '',
          size: '',
          lot_size: '',
          bedroom: '',
          bathroom: '',
          garage: '',
          available_from: '',
          floor_num: '',
          living_room: '',
          user_id: null,
          listed_in: '',
          duration: '',
          developer: '',
          condition_type: '',
          features: [
              {name: 'Air Conditioner', value: false},
              {name: 'Family Lounge', value: false},
              {name: 'Hot Tub', value: false},
              {name: 'Roof Deck', value: false},
              {name: 'Balcony', value: false},
              {name: 'Fenced Yard', value: false},
              {name: 'kitchen', value: false},
              {name: 'Storage', value: false},
              {name: 'Study', value: false},
              {name: 'Ceiling sound system', value: false},
              {name: 'Standby Power supply generator', value: false},
              {name: 'CCTV', value: false},
              {name: 'All Ensuite Bedroom', value: false},
              {name: 'Swimming Pool', value: false},
              {name: 'Back Yard', value: false},
              {name: 'Frontend Yard', value: false},
              {name: 'Basketball Court', value: false},
              {name: 'Security Post', value: false},
              {name: 'Ocean View', value: false},
              {name: 'TV', value: false},
              {name: "Boy's quarter", value: false},
              {name: 'Gym', value: false},
              {name: 'Gazebo', value: false},
              {name: 'Playing Ground', value: false},
          ],
        
      },
      dropOptions: {
          url: `${process.env.VUE_APP_BASEURL}/api/admin/product/upload`,
          autoProcessQueue: false,
          paramName: "image",
          addRemoveLinks: true,
          maxFilesize: 3,
          parallelUploads: 1,
          uploadMultiple: false,
          maxFiles: 6,
          thumbnailWidth: 150, // px
          thumbnailHeight: 150,
          headers: "",
      },
      
      gender: ['male', 'female'],
      listingIn: [
        {name: 'Land', value: 'land'},
        {name: 'Detached', value: 'detached'},
        {name: 'Semi Detached', value: 'semi detached'},
        {name: 'Furnished', value: 'funished'},
        {name: 'Terrace', value: 'terrace'},
        {name: 'Stand Alone', value: 'stand alone'},
        {name: 'Serviced', value: 'serviced'},
      ],
      conditions: [
        {name: 'Newly Build', value: 'Newly Build'},
        {name: 'Fairly Used', value: 'Fairly Used'},
        {name: 'Old', value: 'Old'},
        {name: 'Carcass', value: 'Carcass'},
        {name: 'Under Construction', value: 'Under Construction'},
      ],
  }),
  computed:{
    ...mapState(["countries", 'states','lgas', 'categoriesData']),
    titleErrors () {
        const errors = []
        if (!this.$v.form.title.$dirty) return errors
        !this.$v.form.title.required && errors.push('title is required.')
        return errors
    },
    descriptionErrors () {
        const errors = []
        if (!this.$v.form.description.$dirty) return errors
        !this.$v.form.description.required && errors.push('description is required.')
        return errors
    },
    durationErrors () {
        const errors = []
        if (!this.$v.form.duration.$dirty) return errors
        !this.$v.form.duration.required && errors.push('duration is required.')
        return errors
    },
    newPriceErrors () {
        const errors = []
        if (!this.$v.form.new_price.$dirty) return errors
        !this.$v.form.new_price.required && errors.push(' new price is required.')
        return errors
    },
    categoryErrors () {
        const errors = []
        if (!this.$v.form.category_id.$dirty) return errors
        !this.$v.form.category_id.required && errors.push('category is required.')
        return errors
    },
    conditionErrors () {
        const errors = []
        if (!this.$v.form.condition_type.$dirty) return errors
        !this.$v.form.condition_type.required && errors.push('condition is required.')
        return errors
    },
    propertyTypeErrors () {
        const errors = []
        if (!this.$v.form.product_type.$dirty) return errors
        !this.$v.form.product_type.required && errors.push('property type in is required.')
        return errors
    },
    addressErrors () {
        const errors = []
        if (!this.$v.form.address.$dirty) return errors
        !this.$v.form.address.required && errors.push('address is required.')
        return errors
    },
    stateErrors () {
        const errors = []
        if (!this.$v.form.state_id.$dirty) return errors
        !this.$v.form.state_id.required && errors.push('state is required.')
        return errors
    },
    lgaErrors () {
        const errors = []
        if (!this.$v.form.lga_id.$dirty) return errors
        !this.$v.form.lga_id.required && errors.push('lga is required.')
        return errors
    },
  
      listedErrors () {
        const errors = []
        if (!this.$v.form.listed_in.$dirty) return errors
        !this.$v.form.listed_in.required && errors.push('Listed_In is required.')
        return errors
    }, 
  },
  methods: {
    ...mapActions(["getLocation", "getLga", "addProduct","getCategories"]),
    async submit() {
        this.$v.$touch()
        if (this.$v.$invalid || this.$refs.myVueDropzone.getQueuedFiles().length < 3) {
            if (this.$v.$invalid) {
                this.$toastr.e("Some Fields Are Missing ");
            }
            else{
                this.$toastr.e("minimum of 3 image is required");
                this.imageError = true;
            }
        }
        else {
            this.loading = true;
            this.form.user_id = this.user.id
            let result = await this.addProduct(this.form);
            if(result.success){
                this.product_id = result.data.id;
                this.$refs.myVueDropzone.processQueue()
            }
            else{
                this.$toastr.e(result.message);
                this.loading = false;  
            }
        }
    },
    onChange(event) {
        this.getLga(event);
    },
    save() {
        if (this.$refs.myVueDropzone.getQueuedFiles().length == 0) {
              this.$toastr.e("image cannot be empty ");
        } else {
            console.log('yes')
            this.$refs.myVueDropzone.processQueue()
            
        }
    },
    afterComplete() {
        this.$refs.myVueDropzone.removeAllFiles();
        this.$toastr.s('Product Added Successfully');
        this.loading = false;
        this.$router.push(`/user/my-products`);
    },
    dropzoneError(file, error, xhr){
        this.$toastr.e(error);
        console.log(file)
        console.log(xhr)
    },
    sendingEvent(file, xhr, formData){
        formData.append('product_id', this.product_id);
    },
    async getData(){
    await this.getLocation();
    await this.getCategories()
    },
  },
  created() {
    this.getData();
    let authUser = localStorage.getItem("authUser");
    this.user = JSON.parse(authUser);
    let token = localStorage.getItem("token");
    let bearer = { Authorization: `Bearer ${token}` };
    this.dropOptions.headers = bearer;
  },
};
</script>
