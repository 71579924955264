<template>
  <v-app>
    <v-row >
      <v-col class="col-12 col-sm-3 col-md-3 col-lg-2  d-none d-md-block" >
        <User-Side-Bar />
      </v-col>
      <v-col class="col-12 col-md-9 col-lg-10 grey lighten-3">
        <div :class="{ 'pr-8': $vuetify.breakpoint.smAndDown }">
          <h3 class="mb-6 mt-2 grey--text text--darken-4 text-md-h6">
            <router-link
              class="black--text text-decoration-none"
              :to="`/dashboard`"
            >
              <v-icon class="mt-n1 mr-2 hidden-md-and-up"
                >mdi-arrow-left</v-icon
              >
            </router-link>
            My Properties
          </h3>
          <div class="float-right mt-n8 mr-n3 mr-md-6">
            <Notification />
          </div>
        </div>
        <div class="mt-n10">
          <div
            id="propertyList-main"
            class="pb-16 pt-5"
            :class="{ 'px-0': $vuetify.breakpoint.smAndDown }"
          >
            <div class="properylist-wrapper px-2 py-5 mt-5">
              <div class="hidden-md-and-up">
                <v-btn
                  class="mb-8"
                  color="grey"
                  outlined
                  @click="toggleFilter = !toggleFilter"
                  small
                >
                  <v-icon>mdi-filter-outline</v-icon>filter
                </v-btn>
              </div>
              <div :class="{ 'hidden-sm-and-down': toggleFilter }">
                <div>
                  <v-row>
                    <v-col cols="12" sm="2">
                      <v-select
                        class=""
                        :items="SortItems"
                        label="Order By"
                        v-model="sort"
                        item-text="name"
                        item-value="value"
                        background-color="#F0F0F0"
                        solo
                        flat
                      >
                      </v-select>
                    </v-col>
                    <v-col
                      :class="{ 'mt-n7': $vuetify.breakpoint.xs }"
                      cols="12"
                      sm="2"
                    >
                      <v-select
                        background-color="#F0F0F0"
                        solo
                        flat
                        :items="categories"
                        label="Categories"
                        v-model="cat"
                        item-text="name"
                        item-value="slug"
                      ></v-select>
                    </v-col>
                    <v-col
                      :class="{ 'mt-n7': $vuetify.breakpoint.xs }"
                      cols="12"
                      sm="3"
                    >
                      <v-select
                        background-color="#F0F0F0"
                        :items="PropertyStatus"
                        item-text="name"
                        item-value="value"
                        v-model="property_status"
                        label="Property Status"
                        solo
                        flat
                      ></v-select>
                    </v-col>
                    <v-col
                      :class="{ 'mt-n7': $vuetify.breakpoint.xs }"
                      cols="12"
                      sm="3"
                    >
                      <v-select
                        style=""
                        background-color="#F0F0F0"
                        :items="PropertyType"
                        item-text="name"
                        item-value="value"
                        v-model="property_type"
                        label="Property Type"
                        solo
                        flat
                      >
                      </v-select>
                    </v-col>
                    <v-col
                      :class="{ 'mt-n7': $vuetify.breakpoint.xs }"
                      cols="12"
                      sm="2"
                    >
                      <v-btn
                        v-show="filterLoading == false"
                        id="addlist-button"
                        class=" px-md-6 py-md-6 text-capitalize"
                        color="#0062df"
                        @click="submit"
                      >
                        Filter
                      </v-btn>
                      <v-progress-circular
                        v-show="filterLoading"
                        :width="3"
                        class="ml-4 px-6 py-6"
                        color="green"
                        indeterminate
                      >
                      </v-progress-circular>
                    </v-col>
                  </v-row>
                </div>
              </div>
              <div v-show="skeLoader || processedProducts == false">
                <skeleton-loader/>

              </div>
              <div v-show="skeLoader == false">
                <div class="d-flex flex-wrap">
                  <v-card
                    :width="$vuetify.breakpoint.smAndDown ? '50%' : '25%'"
                    flat
                    color="white"
                    class="pr-1 py-5"
                    v-for="(product, i) in processedProducts"
                    :key="i"
                  >
                    <v-img
                      :src="
                        product.product_images.length > 0
                          ? product.product_images[0].full
                          : `''`
                      "
                      height="200px"
                    >
                      <v-card-title class="mt-n2">
                        <v-btn
                          class="white--text"
                          small
                          v-bind:class="[
                            product.publish == 0
                              ? 'grey'
                              : product.publish == 1
                              ? 'green'
                              : 'red',
                          ]"
                        >
                          {{
                            product.publish == 0
                              ? "Pending"
                              : product.publish == 1
                              ? "Aproved"
                              : "Declined"
                          }}
                        </v-btn>
                      </v-card-title>
                    </v-img>
                    <div
                      class="mt-4 text-subtitle-2 font-weight-bold text-md-subtitle-1"
                    >
                      ₦ {{ product.converted_price }}
                    </div>
                    <span
                      class="mt-n5 mt-md-n6 px-md-1 text-caption font-weight-normal float-right lime darken-3 white--text"
                    >
                      {{ product.product_type }}
                    </span>
                    <v-divider class="divider-color" width="50"></v-divider>
                    <h5
                      style="height: 54px"
                      color="#4A425F"
                      class="subtitle-1 mt-2 font-weight-bold"
                    >
                      {{ product.title | truncate(45) }}
                    </h5>
                    <p
                      color="#4A425F"
                      class="mt-6 mt-md-1 subtitle-2 font-weight-regular"
                      v-text="product.listed_in"
                    ></p>
                    <span class="text-caption grey--text text-lighten-2">
                      {{ `${product.state.name}, ${product.lga.name}` }}
                    </span>
                    <v-row class="ml-1 mt-5 justify-space-between">
                      <v-btn
                        @click="editProduct(product.slug)"
                        outlined
                        color="#1E1C23"
                        class="px-7 subtitle-2 text-capitalize font-weight-regular"
                        >Edit</v-btn
                      >
                      <v-btn
                        @click="deleteItem(product.id)"
                        text
                        color="#4A3907"
                        class="subtitle-2 text-capitalize"
                        >Remove
                        <v-icon color="#4A3907" class="title"
                          >mdi-close</v-icon
                        ></v-btn
                      >
                    </v-row>
                    <v-divider class="mt-8"></v-divider>
                  </v-card>
                  <v-dialog v-model="dialogDelete" max-width="500px">
                    <v-card>
                      <v-card-title class="text-h5"
                        >Are you sure you want to delete this
                        item?</v-card-title
                      >
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                          class="white--text"
                          color="grey darken-3"
                          @click="closeDelete"
                          >Cancel</v-btn
                        >
                        <v-btn
                          v-show="loading == false"
                          class="white--text"
                          color="red lighten-2"
                          @click="deleteItemConfirm"
                          >OK</v-btn
                        >
                        <v-progress-circular
                          v-show="loading"
                          :width="3"
                          class="ml-4"
                          color="green"
                          indeterminate
                        >
                        </v-progress-circular>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-n16" v-show="skeLoader == false">
          <page-control />
        </div>

        <v-container v-if="productListLoading == true">
          <v-sheet class="pa-12" color="grey lighten-3">
            <v-sheet
              :elevation="elevation"
              class="mx-auto text-center"
              color="grey lighten-3"
              ><div>
                No Products Found
              </div></v-sheet
            >
          </v-sheet>
        </v-container>
      </v-col>
    </v-row>
  </v-app>
</template>
<script>
import Notification from "../../components/auth/NotificationWrapper";
import SkeletonLoader from "../layout/SkeletonLoader";
import UserSideBar from "../../components/auth/UserSideBar";
import PageControl from "../auth/PageControl";
import { mapActions, mapGetters, mapState, mapMutations } from "vuex";
export default {
  components: {UserSideBar, PageControl, SkeletonLoader, Notification },
  metaInfo() {
    return {
        title: `My Products - Housefinder.com.ng`,
        
    }
  },
  data: () => ({
    filterLoading: false,
    skeLoader: false,
    model: 1,
    loading: false,
    toggleFilter: true,
    PropertyType: [
      { name: "Any Type", value: "" },
      { name: "Short Let", value: "For Short Let" },
      { name: "Buy", value: "For Sale" },
      { name: "Rent", value: "For Rent" },
    ],
    PropertyStatus: [
      { name: "All Status", value: "" },
      { name: "Approved", value: 1 },
      { name: "Pending", value: 0 },
      { name: "Declined", value: 2 },
    ],
    SortItems: [
      { name: "All", value: "" },
      { name: "Newest First", value: "DESC" },
      { name: "Oldest First", value: "ASC" },
    ],
    property_type: null,
    cat: null,
    property_status: null,
    sort: null,
    dialogDelete: false,
    productId: null,
  }),
  computed: {
    ...mapGetters(["processedProducts", "categories"]),
    ...mapState(["pages", "productListLoading"]),
  },
  methods: {
    ...mapActions(["getUserProducts", "getCategories", "deleteProduct"]),
    ...mapMutations([
      "_setPropertyType",
      "_setCurrentCategory",
      "_setSort",
      "_propertyStatus",
    ]),
    async submit() {
      this.filterLoading = true;
      this.skeLoader = true;
      this._setPropertyType(this.property_type);
      this._setCurrentCategory(this.cat);
      this._setSort(this.sort);
      this._propertyStatus(this.property_status);
      await this.getUserProducts();
      this.filterLoading = false;
      this.skeLoader = false;
    },
    deleteItem(id) {
      this.productId = id;
      this.dialogDelete = true;
    },
    async deleteItemConfirm() {
      this.loading = true;
      let data = await this.deleteProduct(this.productId);
      if (data.data) {
        this.loading = false;
        await this.getUserProducts();
        this.$toastr.s(data.message);
      } else {
        this.$toastr.e(data.message);
      }
      this.closeDelete();
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    editProduct(slug) {
      this.$router.push(`/product/edit/${slug}`);
    },
    async getData() {
      this.skeLoader = true;
      this._setPropertyType(null);
      this._setCurrentCategory(null);
      this._setSort(null);
      this._propertyStatus(null);
      await this.getUserProducts();
      this.skeLoader = false;
      this.getCategories();
    },
  },
  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  created() {
    this.getData();
  },
};
</script>
<style scoped>
#propertyList-main {
  width: 100%;
  height: 100%;
}

.properylist-wrapper {
  background-color: white;
}

#addlist-button {
  color: white;
  text-decoration: none;
}
</style>
