<template>
    <div>
        <v-row>
			<v-col class="col-12 col-sm-3 col-md-3 col-lg-2 d-none d-md-block">
                 <User-Side-Bar/> 
            </v-col>
            <v-col class="col-md-9 col-lg-10 grey lighten-3 px-md-4">
                <div >
                    <h3 class="mb-6 mt-2 grey--text text--darken-4 text-md-h6">
                        <router-link class="black--text text-decoration-none" :to="`/dashboard`">
                            <v-icon class='mt-n1 mr-2 hidden-md-and-up'>mdi-arrow-left</v-icon>
                        </router-link>  
                        Add Package
                    </h3>
                    <div class="float-right mt-n8 mr-6">
						<Notification/>		
					</div>    
                </div>
                <div class="white mt-n2">
                    <v-card
                        :width="$vuetify.breakpoint.smAndDown ? '' : '50%'"
                        flat
                    >
                        <v-form 
                            ref="form" 
                            class="white px-2 mt-10"
                            
                        >
                            <v-container>
                                <h4 class="mb-2">Package Information</h4>
                                <v-row class="mt-n2">
                                    <v-col
                                        cols="12"
                                         sm="6"
                                    >
                                        <v-text-field
                                            color="grey "
                                            v-model="form.name"
                                            label="Package Name"
                                            :rules="[rules.required]"
                                            outlined
                                            filled
                                            
                                        >
                                        </v-text-field>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        sm="6"
                                    >
                                        <v-text-field
                                            color="grey"
                                            v-model="form.price"
                                            label="Price"
                                            outlined
                                            :rules="[rules.required]"
                                            filled
                                            type="number"
                                            
                                            
                                        >
                                        </v-text-field>
                                    </v-col>
                                     <v-col
                                        cols="12"
                                        sm="6"
                                    >
                                        <v-text-field
                                            color="grey"
                                            v-model="form.credit"
                                            label="number of listing"
                                            outlined
                                            :rules="[rules.required]"
                                            filled
                                            type="number"
                                            
                                            
                                        >
                                        </v-text-field>
                                    </v-col>
                                    <v-col
                                        cols="12"
                                        sm="6"
                                    >
                                        <v-text-field
                                            color="grey"
                                            v-model="form.number_of_month"
                                            label="number of month"
                                            :rules="[rules.required]"
                                            outlined
                                            filled
                                            type="number"
                                            
                                        >
                                        </v-text-field>
                                            <div class="float-right">
                                            <v-btn
                                                v-show="loading == false"
                                                @click="updatePassword" 
                                                class="mb-4 lime darken-4 ma-2 white--text"
                                            >
                                                Save
                                            </v-btn>
                                            <v-progress-circular
                                                v-show="loading"
                                                :width="3"
                                                class="mb-4"
                                                color="green"
                                                indeterminate
                                            >
                                            </v-progress-circular>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-form>
                    </v-card>
                </div> 
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import {mapActions } from "vuex";
    import Notification from '../../components/auth/NotificationWrapper';
    import UserSideBar from '../../components/auth/UserSideBar';
    export default {
        components: {UserSideBar, Notification},
        metaInfo() {
            return {
                title: `Add Package - Housefinder.com.ng`,
                
            }
        },
        data(){
            return {
                form: {
                    name: '',
                    credit: '',
                    number_of_month: ''
                },
                loading: false,
                UserId: '',
                rules: {
                    required: value => !!value || 'Required.',
                    min: v => v.length >= 8 || 'Min 8 characters',
                },
            }
        },
        methods: {
            ...mapActions(["addPackage"]),
            async updatePassword(){
                if( this.$refs.form.validate()) {
                    this.loading =true;
                    let data = this.form;
                    let response = await this.addPackage(data);
                    if(response.data.success == true){
                        this.$toastr.s(response.data.message);
                        this.loading = false;
                        this.$router.push(`/packages`);
                    }
                    else{
                        this.$toastr.e(response.data.message);
                        this.loading = false; 
                    }
                }
            },
        },
        created(){
            let authUser = localStorage.getItem('authUser')
            let user = JSON.parse(authUser);
            this.UserId = user.id;
        }
    }
</script>