<template>
  <div>
    <div class="text-center  d-none d-md-block " >
      <v-avatar v-if="userImage">
        <img :src="userImage" alt="profile_image" />
      </v-avatar>
      <v-avatar v-else>
        <img src="../../assets/avatar.png" alt="profile_image" />
      </v-avatar>
      <div class="mt-1 text-subtitle-2">{{ full_name }}</div>
    </div>
    <v-divider></v-divider>
    <v-list class="" >
      <v-list-item-group>
        <v-list-item
          class="mt-n2 text-subtitle-1"
          v-bind:class="{ 'activeBlue darken-2 white--text': currentUrl == '/dashboard' }"
          @click="switchUrl('/dashboard')"
        >
          <v-list-item-icon>
            <v-icon v-text="`mdi-view-dashboard`"></v-icon>
          </v-list-item-icon>
          <v-list-item-content class=" ml-n4 text-subtitle-2">
            <v-list-item-title v-text="`Dashboard`"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-show="
            userType == 'admin' ||
            userType == 'editor' ||
            userType == 'super_admin' ||
            userType == 'admin_can_add'
          "
          class="mt-n2 text-subtitle-1"
          v-bind:class="{
            'activeBlue darken-2 white--text': currentUrl == '/admin/product/index',
          }"
          @click="switchUrl('/admin/product/index')"
        >
          <v-list-item-icon>
            <v-icon v-text="`mdi-home-group`"></v-icon>
          </v-list-item-icon>
          <v-list-item-content class="ml-n4 text-subtitle-2">
            <v-list-item-title v-text="`All Properties`"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-show="
            userType == 'admin' ||
              userType == 'editor' ||
              userType == 'super_admin' ||
              userType == 'admin_can_add'

          "
          class="mt-n2 text-subtitle-1"
          v-bind:class="{ 'activeBlue darken-2 white--text': currentUrl == '/admin/users' }"
          @click="switchUrl('/admin/users')"
        >
          <v-list-item-icon>
            <v-icon v-text="`mdi-account-group`"></v-icon>
          </v-list-item-icon>
          <v-list-item-content class="ml-n4 text-subtitle-2">
            <v-list-item-title v-text="`All Users`"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-show="userType == 'super_admin' ||
            userType == 'editor'
          "    
          class="mt-n2 text-subtitle-1"
          v-bind:class="{
            'activeBlue darken-2 white--text': currentUrl == '/category/index',
          }"
          @click="switchUrl('/category/index')"
        >
          <v-list-item-icon>
            <v-icon v-text="`mdi-tag`"></v-icon>
          </v-list-item-icon>
          <v-list-item-content class="ml-n4 text-subtitle-2">
            <v-list-item-title v-text="`Categories`"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list-item
          class="mt-n2 text-subtitle-1"
          v-bind:class="{ 'activeBlue darken-2 white--text': currentUrl == item.url }"
          v-for="(item, i) in items"
          :key="i"
          @click="switchUrl(item.url)"
        >
          <v-list-item-icon>
            <v-icon v-text="item.icon"></v-icon>
          </v-list-item-icon>
          <v-list-item-content class="ml-n4 text-subtitle-2">
            <v-list-item-title>{{ item.text }}</v-list-item-title>
          </v-list-item-content>
          <v-badge
            v-show="item.text == 'Inbox' && msgCount > 0"
            color="green"
            :content="msgCount"
            class="float-right mr-2 mt-2"
          >
          </v-badge>
        </v-list-item>
        <v-list-item
          v-show="userType == 'super_admin' || userType == 'editor'"
          class="mt-n2 text-subtitle-1 "
          v-bind:class="{
            'activeBlue darken-2 white--text': currentUrl == '/auth/verify-user',
          }"
          @click="switchUrl('/auth/verify-user')"
        >
          <v-list-item-icon>
            <v-icon v-text="`mdi-check-decagram`"></v-icon>
          </v-list-item-icon>
          <v-list-item-content class="ml-n4 text-subtitle-2">
            <v-list-item-title v-text="`Verify User`"></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  data: () => ({
    full_name: "",
    currentUrl: "",
    model: 1,
    userType: "",
    userImage: "",
    items: [
      {
        icon: "mdi-home-alert",
        text: "My Properties",
        url: "/user/my-products",
      },
      {
        icon: "mdi-plus-circle-outline",
        text: "Add Property",
        url: "/product/create",
      },
      {
        icon: "mdi-email-open",
        text: "Inbox",
        url: "/user/chats",
      },
      {
        icon: "mdi-account",
        text: "My Profile",
        url: "/user/profile",
      },
      {
        icon: "mdi-shield-check",
        text: "Verification",
        url: "/auth/verification",
      },
      {
        icon: "mdi-lock",
        text: "Reset Password",
        url: "/change-password",
      },
    ],
  }),
  computed: {
    ...mapState({ msgCount: state => state.auth.unreadMessage }),
  },
  methods: {
    ...mapActions(["unreadMessageCount"]),
    getMessageCount() {
      this.unreadMessageCount();
    },
    switchUrl(url) {
      this.$router.push(`${url}`);
    },
  },
  created() {
    let data = localStorage.getItem("authUser");
    let authUser = JSON.parse(data);
    this.full_name = authUser.full_name;
    this.userImage = authUser.image;
    this.currentUrl = this.$router.history.current.path;
    this.userType = localStorage.getItem("userType");
    this.getMessageCount();
  },
};
</script>
<style lang="scss" scoped>
.activeBlue {
  background: #0062df;
}
</style>
