<template>
  <v-app>
    <v-row>
			<v-col class="col-12 col-sm-3 col-md-3 col-lg-2">
        <User-Side-Bar/> 
      </v-col>
      <v-col class="col-12 col-md-9 col-lg-10 grey lighten-3 ">
				<div v-if="skeLoader">
					<skeleton-loader/>
				</div>
        <div
          class="d-flex px-0 py-0"
          :class="{ 'd-flex flex-column': $vuetify.breakpoint.smAndDown }"
        >
          <div
            id="propertyList-main"
            class="px-5 pt-5"
            :class="{ 'px-16': $vuetify.breakpoint.mdAndUp }"
          >
            <h2 class="subtitle-2 font-weight-regular">Welcome <span class="font-weight-bold black--text">{{full_name}}</span></h2>
            <div :class="{'pr-8': $vuetify.breakpoint.smAndDown}">
              <h3 class="mb-6 mt-2 grey--text text--darken-2"><router-link class="black--text text-decoration-none" :to="`/dashboard`">Dashboard </router-link></h3>
              <div class="float-right mt-n10">
                <Notification/>		
              </div>    
            </div>
            
            <div class="mt-3">
                <div class="mt-10">
                    <h2 class=" mb-4 ">See Available Packages</h2>
                    <v-row :class="{ 'grid': $vuetify.breakpoint.mdAndUp }">
                      <v-flex xs12 sm3>
                        <v-col>
                            <v-card class="text-center pt-3 grid-cols-1">
                                <h1 class="title">Free Package</h1>
                                <p class="card-price-price font-semibold title mt-2">#0.00</p>
                                <p>Unlimited Listings</p>
                                <p>8 Featured</p>
                                <p>5 Images / per listing</p>
                                <div class="py-2 card-price-bottom-selected white--text">
                                    <p class="mt-2">Select Package</p>
                                </div>
                            </v-card>
                        </v-col>
                      </v-flex>
                      <v-flex xs12 sm3>
                        <v-col>
                            <v-card class="text-center pt-3 grid-cols-1">
                                <h1 class="title">Silver Package</h1>
                                <p class="card-price-price font-semibold title mt-2">#0.00</p>
                                <p>Unlimited Listings</p>
                                <p>8 Featured</p>
                                <p>5 Images / per listing</p>
                                <div class="py-3 card-price-bottom white--text">
                                    <p>Select Package</p>
                                </div>
                            </v-card>
                        </v-col>
                      </v-flex>
                      <v-flex xs12 sm3>
                        <v-col>
                            <v-card class="text-center pt-3 grid-cols-1" text-color="#837E91">
                                <h1 class="title">Gold Package</h1>
                                <p class="card-price-price font-semibold title mt-2">#0.00</p>
                                <p>Unlimited Listings</p>
                                <p>8 Featured</p>
                                <p>5 Images / per listing</p>
                                <div class="py-3 card-price-bottom white--text">
                                    <p>Select Package</p>

                                </div>
                            </v-card>
                        </v-col>
                      </v-flex>
                      <v-flex xs12 sm3>
                        <v-col>
                            <v-card class="text-center pt-3 grid-cols-1">
                                <h1 class="title">Premium Package</h1>
                                <p class="card-price-price font-semibold title mt-2">#0.00</p>
                                <p>Unlimited Listings</p>
                                <p>8 Featured</p>
                                <p>5 Images / per listing</p>
                                <div class="py-3 card-price-bottom white--text">
                                    <p>Select Package</p>
                                </div>
                            </v-card>
                        </v-col>
                      </v-flex>
                    </v-row>
                </div>
            </div>
          </div>
        </div>
      </v-col>
		</v-row>
  </v-app>
</template>
<script>
import SkeletonLoader from '../layout/SkeletonLoader';
import Notification from '../../components/auth/NotificationWrapper';
import UserSideBar from '../../components/auth/UserSideBar';
export default {
  components: {UserSideBar, Notification, SkeletonLoader},
  metaInfo() {
    return {
      title: `Dashboard - Housefinder`,
        
    }
  },
  data () {
    return {
      full_name: '',
      checkbox: true,
      skeLoader: false,
    }
  },
  created(){
    let data = localStorage.getItem('authUser')
    let authUser = JSON.parse(data);
    this.full_name = authUser.full_name;
  }
}
</script>
<style scoped>
#propertyList-main {
  width: 100%;
}

.properylist-wrapper {
  background-color: white;
}

.card-property-title {
  color: #4A425F;
}

.card-category-title {
  color: #837E91;
}
.card-price-bottom {
    background-color: #837E91;
}

.card-price-bottom-selected {
    background-color: #6951A4;
}

.card-price-price {
    color: #896D19;
}

</style>
