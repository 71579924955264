<template>
  <div>
    <v-row>
      <v-col class="col-12 col-sm-3 col-md-3 col-lg-2 d-none d-md-block">
        <User-Side-Bar />
      </v-col>
      <v-col class="col-md-6 col-lg-7 grey lighten-3 px-2 pl-md-4">
        <div :class="{ 'pr-8': $vuetify.breakpoint.smAndDown }">
          <h3 class="mb-6 mt-2 grey--text text--darken-4 text-md-h6">
            <router-link
              class="black--text text-decoration-none"
              :to="`/dashboard`"
            >
              <v-icon class="mt-n1 mr-2 hidden-md-and-up"
                >mdi-arrow-left</v-icon
              >
            </router-link>
            Profile
          </h3>
          <div class="float-right mt-n8  d-md-none">
            <Notification />
          </div>
        </div>
        <v-form class="white px-2">
          <v-container>
            <h4 class="mb-2">Personal Details</h4>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  color="grey"
                  v-model="form.first_name"
                  label="First Name"
                  :error-messages="firstNameErrors"
                  @input="$v.form.first_name.$touch()"
                  @blur="$v.form.first_name.$touch()"
                  outlined
                  filled
                  :disabled="form.user_type == 'admin_can_add'"
                >
                </v-text-field>
              </v-col>
              <v-col
                :class="{ 'mt-n8': $vuetify.breakpoint.xsOnly }"
                cols="12"
                sm="6"
              >
                <v-text-field
                  color="grey"
                  v-model="form.last_name"
                  label="Last Name"
                  :error-messages="lastNameErrors"
                  @input="$v.form.last_name.$touch()"
                  @blur="$v.form.last_name.$touch()"
                  outlined
                  filled
                  :disabled="form.user_type == 'admin_can_add'"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-n8">
              <v-col cols="12" sm="6">
                <v-text-field
                  color="grey"
                  v-model="form.phone_number"
                  :error-messages="phoneErrors"
                  @input="$v.form.phone_number.$touch()"
                  @blur="$v.form.phone_number.$touch()"
                  :disabled="form.user_type == 'admin_can_add'"
                  label="Phone Number"
                  outlined
                  type="tel"
                  filled
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  :class="{ 'mt-n8': $vuetify.breakpoint.xsOnly }"
                  color="grey ma-16"
                  v-model="form.email"
                  :error-messages="emailErrors"
                  @input="$v.form.email.$touch()"
                  @blur="$v.form.email.$touch()"
                  label="Email Address"
                  type="email"
                  outlined
                  filled
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-n8">
              <v-col cols="12" sm="3">
                <v-select
                  :items="gender"
                  label="Gender"
                  :error-messages="genderErrors"
                  @input="$v.form.gender.$touch()"
                  @blur="$v.form.gender.$touch()"
                  item-text="name"
                  item-value="value"
                  v-model="form.gender"
                  outlined
                  filled
                >
                </v-select>
              </v-col>
              <v-col
                :class="{ 'mt-n8': $vuetify.breakpoint.xsOnly }"
                cols="12"
                sm="5"
              >
                <v-text-field
                  color="grey ma-16"
                  v-model="form.dob"
                  :error-messages="dobErrors"
                  @input="$v.form.dob.$touch()"
                  @blur="$v.form.dob.$touch()"
                  label="Date of Birth"
                  outlined
                  filled
                  type="date"
                >
                </v-text-field>
              </v-col>
              <v-col
                :class="{ 'mt-n8': $vuetify.breakpoint.xsOnly }"
                cols="12"
                sm="4"
              >
                <v-text-field
                  color="grey ma-16"
                  v-model="form.member_of"
                  label="Member of"
                  outlined
                  filled
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="mt-n8">
              <v-col cols="12" sm="4">
                <v-select
                  :items="countries"
                  v-model="form.nationality_id"
                  @input="$v.form.nationality_id.$touch()"
                  @blur="$v.form.nationality_id.$touch()"
                  item-text="name"
                  item-value="id"
                  :error-messages="countryErrors"
                  label="Nationality"
                  outlined
                  filled
                >
                </v-select>
              </v-col>
              <v-col
                :class="{ 'mt-n8': $vuetify.breakpoint.xsOnly }"
                cols="12"
                sm="4"
              >
                <v-select
                  :items="states"
                  :error-messages="stateErrors"
                  @input="$v.form.state_id.$touch()"
                  @blur="$v.form.state_id.$touch()"
                  v-on:change="onChange"
                  item-text="name"
                  item-value="id"
                  v-model="form.state_id"
                  label="State"
                  outlined
                  filled
                >
                </v-select>
              </v-col>
              <v-col
                :class="{ 'mt-n8': $vuetify.breakpoint.xsOnly }"
                cols="12"
                sm="4"
              >
                <v-select
                  :items="lgas"
                  :error-messages="lgaErrors"
                  @input="$v.form.lga_id.$touch()"
                  @blur="$v.form.lga_id.$touch()"
                  item-text="name"
                  item-value="id"
                  v-model="form.lga_id"
                  label="Lga"
                  outlined
                  filled
                >
                </v-select>
              </v-col>
              <v-col class="mt-n8" cols="12" sm="8">
                <v-text-field
                  :error-messages="addressErrors"
                  @input="$v.form.address.$touch()"
                  @blur="$v.form.address.$touch()"
                  v-model="form.address"
                  label="Residential Address"
                  outlined
                  filled
                >
                </v-text-field>
              </v-col>
              <v-col class="mt-n8" cols="12" sm="4">
                <v-text-field
                  v-model="form.user_type"
                  label="user_type"
                  outlined
                  filled
                  disabled
                >
                </v-text-field>
              </v-col>
            </v-row>
            <h4 class="mb-2">Agent Specialisation/Category</h4>
            <v-row class="mt-n2">
              <v-col cols="12" sm="6">
                <v-select
                  :items="categories"
                  item-text="name"
                  item-value="value"
                  label="category"
                  v-model="form.agent.category"
                  outlined
                  filled
                >
                </v-select>
              </v-col>
              <v-col
                :class="{ 'mt-n8': $vuetify.breakpoint.xsOnly }"
                cols="12"
                sm="6"
              >
                <v-select
                  label="Action Category"
                  :items="categoryActions"
                  item-text="name"
                  item-value="value"
                  outlined
                  filled
                  v-model="form.agent.action_catigory"
                >
                </v-select>
              </v-col>
            </v-row>
            <h4 class="mb-2">Social Profile</h4>
            <v-row class="mt-n2">
              <v-col cols="12" sm="6">
                <v-text-field
                  color="grey ma-16"
                  v-model="form.agent.facebook_url"
                  label="Facebook_url"
                  outlined
                  filled
                  append-icon="mdi-link-variant"
                >
                </v-text-field>
              </v-col>
              <v-col
                :class="{ 'mt-n8': $vuetify.breakpoint.xsOnly }"
                cols="12"
                sm="6"
              >
                <v-text-field
                  color="grey ma-16"
                  v-model="form.agent.instagram_url"
                  label="Instagram_url"
                  outlined
                  filled
                  append-icon="mdi-link-variant"
                >
                </v-text-field>
              </v-col>
              <v-col class="mt-n8" cols="12" sm="6">
                <v-text-field
                  color="grey ma-16"
                  v-model="form.agent.linkedin_url"
                  label="Linkedin_url"
                  outlined
                  filled
                  append-icon="mdi-link-variant"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <h4 class="mb-2">Agent Details</h4>
            <v-row class="mt-n2">
              <v-col cols="12">
                <v-text-field
                  color="grey ma-16"
                  v-model="form.agent.title"
                  label="Title/Profile"
                  outlined
                  filled
                  :disabled="form.user_type == 'admin_can_add'"
                >
                </v-text-field>
              </v-col>
              <v-col class="mt-n8" cols="12">
                <v-textarea
                  outlined
                  v-model="form.agent.about"
                  name="input-7-4"
                  filled
                  label="About"
                >
                </v-textarea>
                <div class="float-right">
                  <v-btn
                    v-show="loading2 == false"
                    @click="updateProfile"
                    color="#0062df"
                    depressed
                    class="py-md-4 mb-4 darken-4 ma-2 white--text"
                    :class="{ 'mt-n4': $vuetify.breakpoint.xsOnly }"
                  >
                    Update
                  </v-btn>
                  <v-progress-circular
                    v-show="loading2"
                    :width="3"
                    class="mb-4"
                    color="green"
                    indeterminate
                  >
                  </v-progress-circular>
                </div>
              </v-col>
            </v-row>
          </v-container>
        </v-form>
      </v-col>
      <v-col class="col-12 col-md-3 grey lighten-3 ">
        <div class="container d-none d-md-block">
          <div class="container d-none d-md-block ml-16 mt-2">
            <Notification />
          </div>
        </div>
        <v-container class="white mt-n6 mt-lg-n6">
          <div class="text-center mt-3">
            <img v-if="form.image" height="100" :src="form.image" />

            <img
              v-else
              height="100"
              src="../../assets/avatar.png"
              alt="profile_image"
            />
            <vue-dropzone
              id="drop1"
              ref="myVueDropzone"
              :options="dropOptions"
              @vdropzone-error="dropzoneError"
              @vdropzone-queue-complete="afterComplete"
              @vdropzone-success="afterSuccess"
              @vdropzone-sending="sendingEvent"
            >
            </vue-dropzone>
            <span v-show="imageError" class="red--text">image is required</span>
            <div class="mt-2">
              <v-btn
                v-show="loading3 == false"
                @click="updateImage"
                color="#0062df"
                depressed
                class=" darken-4 white--text"
              >
                Upload
              </v-btn>
              <v-progress-circular
                v-show="loading3"
                :width="3"
                class="mb-4"
                color="green"
                indeterminate
              >
              </v-progress-circular>
            </div>
            <div class="text-subtitle-2">Maximum size 2mb</div>
          </div>
        </v-container>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import Notification from "../../components/auth/NotificationWrapper";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import { mapState, mapActions } from "vuex";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import vueDropzone from "vue2-dropzone";
import UserSideBar from "../../components/auth/UserSideBar";
export default {
  components: { vueDropzone, UserSideBar, Notification },
  mixins: [validationMixin],
  validations: {
    form: {
      first_name: { required },
      last_name: { required },
      email: { required },
      phone_number: { required },
      gender: { required },
      dob: { required },
      nationality_id: { required },
      state_id: { required },
      lga_id: { required },
      address: { required },
    },
  },
  data: () => ({
    loading: false,
    loading2: false,
    loading3: false,
    new_password: "",
    current_password: "",
    confirm_new_password: "",
    form: {},
    model: 1,
    gender: [
      { name: "Male", value: "male" },
      { name: "Female", value: "female" },
    ],
    stat: [
      { name: "america", id: "4" },
      { name: "nigeria", id: "3" },
    ],
    dropOptions: {
      url: `${process.env.VUE_APP_BASEURL}/api/user/image/update`,
      autoProcessQueue: false,
      paramName: "image",
      addRemoveLinks: true,
      maxFilesize: 1,
      parallelUploads: 3,
      uploadMultiple: false,
      maxFiles: 1,
      thumbnailWidth: 150, // px
      thumbnailHeight: 150,
      headers: "",
    },
    categories: [
      { name: "Commercial", value: "commercial" },
      { name: "Commercial & Residential", value: "commercial_residential" },
      { name: "Residential", value: "residential" },
      { name: "Consultancy", value: "consultancy" },
    ],
    categoryActions: [
      { name: "Auctions", value: "actions" },
      { name: "Complete Service", value: "complete_service" },
      { name: "Fore Closure", value: "fore_closure" },
    ],
    imageError: false,
    rules: {
      required: value => !!value || "Required.",
      min: v => v.length >= 8 || "Min 8 characters",
    },
  }),
  computed: {
    ...mapState(["states", "countries", "lgas"]),
    firstNameErrors() {
      const errors = [];
      if (!this.$v.form.first_name.$dirty) return errors;
      !this.$v.form.first_name.required &&
        errors.push("First Name is required.");
      return errors;
    },
    lastNameErrors() {
      const errors = [];
      if (!this.$v.form.last_name.$dirty) return errors;
      !this.$v.form.last_name.required && errors.push("Last Name is required.");
      return errors;
    },
    phoneErrors() {
      const errors = [];
      if (!this.$v.form.phone_number.$dirty) return errors;
      !this.$v.form.phone_number.required &&
        errors.push("Phone Number is required.");
      return errors;
    },
    emailErrors() {
      const errors = [];
      if (!this.$v.form.email.$dirty) return errors;
      !this.$v.form.email.required && errors.push("Email is required.");
      return errors;
    },
    genderErrors() {
      const errors = [];
      if (!this.$v.form.gender.$dirty) return errors;
      !this.$v.form.gender.required && errors.push("Gender is required.");
      return errors;
    },
    dobErrors() {
      const errors = [];
      if (!this.$v.form.dob.$dirty) return errors;
      !this.$v.form.dob.required && errors.push("DOB is required.");
      return errors;
    },
    countryErrors() {
      const errors = [];
      if (!this.$v.form.nationality_id.$dirty) return errors;
      !this.$v.form.nationality_id.required &&
        errors.push("Nationality is required.");
      return errors;
    },
    stateErrors() {
      const errors = [];
      if (!this.$v.form.state_id.$dirty) return errors;
      !this.$v.form.state_id.required && errors.push("State is required.");
      return errors;
    },
    lgaErrors() {
      const errors = [];
      if (!this.$v.form.lga_id.$dirty) return errors;
      !this.$v.form.lga_id.required && errors.push("Lga is required.");
      return errors;
    },
    addressErrors() {
      const errors = [];
      if (!this.$v.form.address.$dirty) return errors;
      !this.$v.form.address.required && errors.push("Lga is required.");
      return errors;
    },
    passwordErrors() {
      const errors = [];
      if (!this.$v.current_password.$dirty) return errors;
      !this.$v.form.current_password && errors.push("Lga is required.");
      return errors;
    },
  },
  methods: {
    ...mapActions(["getLocation", "getLga", "updateUser", "resetPassword"]),
    onChange(event) {
      this.getLga(event);
    },
    async updateProfile() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.loading2 = true;
        let data = this.form;
        console.log(data);
        let response = await this.updateUser(data);
        if (response.data.success == true) {
          this.$toastr.s("Update successful");
          this.loading2 = false;
        } else {
          this.$toastr.e(response.data.message);
          this.loading2 = false;
        }
      } else {
        this.$toastr.e("an error occcured");
      }
    },
    async updatePassword() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        let data = {
          new_password: this.new_password,
          current_password: this.current_password,
          id: this.form.id,
        };
        console.log(data);
        let response = await this.resetPassword(data);
        if (response.data.success == true) {
          this.$toastr.s(response.data.message);
          this.loading = false;
        } else {
          this.$toastr.e(response.data.message);
          this.loading = false;
        }
      }
    },
    async loadLocation() {
      let authUser = localStorage.getItem("authUser");
      this.form = JSON.parse(authUser);
      await this.getLocation();
      this.onChange(this.form.state_id);
    },
    updateImage() {
      if (this.$refs.myVueDropzone.getQueuedFiles().length == 0) {
        this.$toastr.e("image cannot be empty ");
        this.imageError = true;
      } else {
        this.loading3 = true;
        this.$refs.myVueDropzone.processQueue();
      }
    },
    afterComplete() {
      this.$refs.myVueDropzone.removeAllFiles();
    },
    afterSuccess(file, response) {
      console.log(file);
      if (response.success == true) {
        this.form.image = response.data.image;
        this.$toastr.s(response.message);
        localStorage.setItem("authUser", JSON.stringify(this.form));
        this.loading3 = false;
      } else {
        this.$toastr.e(response.message);
      } 
    },
    sendingEvent(file, xhr, formData) {
      formData.append("id", this.form.id);
    },
    dropzoneError(file, error, xhr){
      this.$toastr.e(error);
      console.log(file)
      console.log(xhr)
    },
  },
  created() {
    this.loadLocation();
    let token = localStorage.getItem("token");
    let bearer = { Authorization: `Bearer ${token}` };
    this.dropOptions.headers = bearer;
  },
};
</script>
