<template>
    <v-dialog
        v-model="dialog"
        max-width="500px"
    >
        <template v-slot:activator="{ on, attrs }">
            <span  v-on="on" v-if="unreadNotify > 0"> 
                <v-badge
                    v-show="unreadNotify > 0"
                    color="red"
                    :content="unreadNotify"
                    class="mb-2"
                    v-bind="attrs"
                
                >
                    <v-icon class="float-right mt-n3" v-bind="attrs" >mdi-bell-outline</v-icon>
                </v-badge>
            </span>
            <span  v-else v-bind="attrs" v-on="on"> 
                <v-icon class="mb-2 mt-n3"  >mdi-bell-outline</v-icon>
            </span>
        </template>
        <v-card>
            <v-card-title class="mb-n3 blue darken-2 white--text">
                <span class="">Notifications</span>
                <v-spacer></v-spacer>
                <v-btn
                    v-show="unreadNotify > 0"
                    color="red darken-3 white--text"
                    @click="RemoveAllNotify"
                    small
                >
                    Delete All
                </v-btn>
                <v-dialog 
                    v-model="dialogDelete" 
                    max-width="500px"
                    persistent 
                    :retain-focus="false"
                >
                    <v-card>
                        <v-card-title class="text-h6">Are you sure you want to delete all Notifications?</v-card-title>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn class="white--text" small color="grey darken-3"  @click="closeDelete">Cancel</v-btn>
                            <v-btn v-show="loading == false"  small class="white--text" color="red lighten-2"  @click="deleteItemConfirm">OK</v-btn>
                            <v-progress-circular
                                v-show="loading"
                                :width="3"
                                class="ml-4"
                                color="green"
                                indeterminate
                            >
                            </v-progress-circular>
                            <v-spacer></v-spacer>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-card-title>
                <Notification/>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="grey darken-3 white--text"
                @click="close"
                small
            >
                Cancel
            </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import {mapActions, mapState} from "vuex";
	import Notification from '../../components/auth/Notification';
	export default {
		components: {Notification},
		data: () => ({
            unreadNotify: 0,
			dialog: false,
            dialogDelete: false,
            loading: false,
        }),
        computed: {
            ...mapState({notify: state => state.auth.userNotify}),
        },
		watch: {
			dialog (val) {
				val || this.close()
			},
            dialogDelete (val) {
                val || this.closeDelete()
            }, 
            $route(to, from) { 
                if(to.params.slug !== from.params.slug){
                    this.close();
                    
                } 
            },
        },
		methods: {
              ...mapActions(["getUserNotify", "deleteAllNotify"]),
			close () {
				this.dialog = false
			},
            async loadNotify(){
                await this.getUserNotify();
                console.log(this.notify.notify)
                this.unreadNotify = this.notify.unread_notify;
            },
            RemoveAllNotify() {
                this.dialogDelete = true
            },
            async deleteItemConfirm(){
                this.loading = true;
                let response = await this.deleteAllNotify();
                if(response.success){
                        this.$toastr.s(response.message);
                        this.unreadNotify = 0;
                        this.closeDelete()
                        this.loading = false;
            }
                else{
                    this.$toastr.e(response);
                    this.loading = false;
                    this.closeDelete()
                }
                
            },
            closeDelete () {
                this.dialogDelete = false
            },
        },
        created(){
            this.loadNotify();
        }
	}
</script>