<template>
	<div>
		<v-row>
			<v-col class="col-12 col-sm-3 col-md-3 col-lg-2 d-none d-md-block">
                 <User-Side-Bar/> 
            </v-col>
            <v-col class="col-md-9 col-lg-10 grey lighten-3 px-md-4">
                <div :class="{'pr-8': $vuetify.breakpoint.smAndDown}">
					<h3 class="mb-6 mt-2 grey--text text--darken-4 text-md-h6">
                        <router-link class="black--text text-decoration-none" :to="`/dashboard`">
                            <v-icon class='mt-n1 mr-2 hidden-md-and-up'>mdi-arrow-left</v-icon>
                        </router-link>  
                        transactions
                    </h3>
                    <div class="float-right mt-n8 mr-n3 mr-md-6">
						<Notification/>		
					</div>    
                </div>
				<div v-show="skeLoader">
					<skeleton-loader/>
				</div>
				<v-tabs
					class="mb-8"
					v-model="tab"
					align-with-title
					
				>
					<v-tabs-slider color="yellow"></v-tabs-slider>

					<v-tab
					
					>
						Transactions
					</v-tab>
					<v-tab
					>
						Orders
					</v-tab>
					<v-tab
					>
						My Package
					</v-tab>
				</v-tabs>
				<v-tabs-items v-model="tab">
					<v-tab-item
					
					>
						<v-data-table
							v-show="skeLoader == false"
							:item-class="textColor"
							:headers="tranHeaders"
							:items="transactions"
							sort-by="type"
							class="elevation-1"
							:search="tranSearch"
						> 
                            <template v-slot:top>
								<v-toolbar
								flat
								>
									<v-text-field
										v-model="tranSearch"
										append-icon="mdi-magnify"
										label="Search"
										single-line
										hide-details
									>
									</v-text-field>
									
								</v-toolbar>
							</template>  
							
						</v-data-table>
					</v-tab-item>

					<v-tab-item>
						<v-data-table
							v-show="skeLoader == false"
							:item-class="textColor"
							:headers="orderHeaders"
							:items="orders"
							sort-by="order_number"
							class="elevation-1"
							:search="orderSearch"
						>
                            <template v-slot:top>
								<v-toolbar
								flat
								>
									<v-text-field
										v-model="orderSearch"
										append-icon="mdi-magnify"
										label="Search"
										single-line
										hide-details
									>
									</v-text-field>
									
								</v-toolbar>
							</template> 
						</v-data-table>
					</v-tab-item>

					<v-tab-item>
						
					</v-tab-item>
				</v-tabs-items>
			</v-col>
		</v-row>
	</div>
</template>

<script>
	import SkeletonLoader from '../layout/SkeletonLoader';
	import { mapActions, mapState } from "vuex";
	import UserSideBar from '../../components/auth/UserSideBar';
	import Notification from '../../components/auth/NotificationWrapper';
	export default {
		components: {UserSideBar, Notification, SkeletonLoader},
		metaInfo() {
			return {
				title: `Property List - Housefinder.com.ng`,
				
			}
		},
		data: () => ({
			authUserType: '',
			tab: null,
			skeLoader: false,
			loading: false,
            orders: [],
            transactions: [],
            userPackage: '',
			tranSearch: '',
            orderSearch: '',
            orderHeaders: [
				{
				text: 'Order Number',
				align: 'start',
				sortable: false,
				value: 'order_number',
				},
				{ text: 'Number of Listing', value: 'credit' },
				{ text: 'Package Name', value: 'package_name' },
				{ text: 'Price', value: 'price' },
                { text: 'Reference', value: 'reference' },
                { text: 'Payment Status', value: 'payment_status' },
				{ text: 'Date', value: 'created_at' },
			],
            tranHeaders: [
				{
				text: 'Type',
				align: 'start',
				sortable: false,
				value: 'type',
				},
				{ text: 'Credit', value: 'credit' },
                { text: 'Description', value: 'meta' },
				{ text: 'Date', value: 'created_at' },
			],	
		}),

		computed: {
			...mapState(['products']),
			formTitle () {
				return this.editedIndex === -1 ? 'New Item' : 'Edit Item'
			},
		},
		methods: {
			...mapActions(["userTransaction"]),
			textColor(item){
				return item.publish == 2 ? 'red--text' : ''
			},
			async getData(){
				this.authUserType = localStorage.getItem('userType');
				this.skeLoader = true;
                let response = await this.userTransaction();
                this.orders = response.orders;
                this.transactions = response.transactions;
                this.userPackage = response.user_package;
				this.skeLoader = false;

			},
		},
		created () {
			this.getData();
		},
	}
</script>