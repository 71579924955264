<template>
    <div>
        <v-row>
            <v-col class="col-12 col-sm-3 col-md-3 col-lg-2 d-none d-md-block">
                 <User-Side-Bar/> 
            </v-col>
            <v-col class="col-md-6 col-lg-7 grey lighten-3 px-2 pl-md-4">
                <div :class="{'pr-8': $vuetify.breakpoint.smAndDown}">
                    <h3 class="mb-6 mt-2 grey--text text--darken-4 text-md-h6">
                        <router-link class="black--text text-decoration-none" :to="`/dashboard`">
                            <v-icon class='mt-n1 mr-2 hidden-md-and-up'>mdi-arrow-left</v-icon>
                        </router-link>  
                        Admin Update
                    </h3>
                    <div class="float-right mt-n8  d-md-none">
						<Notification/>		
					</div>    
                </div>
                <div v-show="skeLoader">
					<skeleton-loader/>
				</div>
                <v-form class="white px-2" 	v-show="skeLoader == false">
                    <v-container>
                        <h4 class="mb-2">Personal Details</h4>
                        <v-row >
                            <v-col
                                cols="12"
                                sm="6"
                            >   
                                <v-text-field
                                    color="grey"
                                    v-model="form.first_name"
                                    label="First Name"
                                    :error-messages="firstNameErrors"
                                    @input="$v.form.first_name.$touch()"
                                    @blur="$v.form.first_name.$touch()"
                                    outlined
                                    filled
                                >
                                </v-text-field>
                            </v-col>
                            <v-col
                                class=""
                                cols="12"
                                sm="6"
                            >
                                <v-text-field
                                    color="grey"
                                    :class="{'mt-n8': $vuetify.breakpoint.xsOnly}"
                                    v-model="form.last_name"
                                    label="Last Name"
                                    :error-messages="lastNameErrors"
                                    @input="$v.form.last_name.$touch()"
                                    @blur="$v.form.last_name.$touch()"
                                    outlined
                                    filled
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="mt-n8">
                            <v-col
                                cols="12"
                                sm="6"
                            >
                                <v-text-field
                                    color="grey"
                                    v-model="form.phone_number"
                                    :error-messages="phoneErrors"
                                    @input="$v.form.phone_number.$touch()"
                                    @blur="$v.form.phone_number.$touch()"
                                    label="Phone Number"
                                    outlined
                                    type="tel"
                                    filled
                                   
                                >
                                </v-text-field>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="6"
                            >
                                <v-text-field
                                    :class="{'mt-n8': $vuetify.breakpoint.xsOnly}"
                                    color="grey ma-16"
                                    v-model="form.email"
                                    :error-messages="emailErrors"
                                    @input="$v.form.email.$touch()"
                                    @blur="$v.form.email.$touch()"
                                    label="Email Address"
                                    type="email"
                                    outlined
                                    filled
                                    
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <v-row class="mt-n8">
                            <v-col
                                cols="12"
                                sm="4"
                            >
                                <v-select
                                    :items="gender"
                                    label="Gender"
                                    :error-messages="genderErrors"
                                    @input="$v.form.gender.$touch()"
                                    @blur="$v.form.gender.$touch()"
                                    item-text="name"
                                    item-value="value"
                                    v-model="form.gender"
                                    outlined
                                    filled
                                >
                                </v-select>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="4"
                            >
                                <v-text-field
                                    :class="{'mt-n8': $vuetify.breakpoint.xsOnly}"
                                    color="grey ma-16"
                                    v-model="form.dob"
                                    label="Date of Birth"
                                    outlined
                                    filled
                                    type="date"
                                    
                                >
                                </v-text-field>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="4"
                            >
                                <v-text-field
                                    :class="{'mt-n8': $vuetify.breakpoint.xsOnly}"
                                    color="grey ma-16"
                                    v-model="form.member_of"
                                    label="Member of"
                                    outlined
                                    filled
                                    
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                          <v-row class="mt-n8">
                            <v-col
                                cols="4"
                                sm="4"
                            >
                                <v-select
                                    :items="countries"
                                    v-model="form.nationality_id"
                                    item-text="name"
                                    item-value="id"
                                    label="Country"
                                    outlined
                                    filled
                                >
                                </v-select>
                            </v-col>
                            <v-col
                                cols="4"
                                sm="4"
                            >
                                 <v-select
                                    :items="states"
                                    v-on:change="onChange"
                                    item-text="name"
                                    item-value="id"
                                    v-model="form.state_id"
                                    label="State"
                                    outlined
                                    filled
                                >
                                </v-select>
                            </v-col>
                             <v-col
                                cols="4"
                                sm="4"
                            >
                                <v-select
                                    :items="lgas"
                                    item-text="name"
                                    item-value="id"
                                    v-model="form.lga_id"
                                    label="Lga"
                                    outlined
                                    filled
                                >
                                </v-select>
                            </v-col>
                            <v-col
                                :class="{'mt-n8': $vuetify.breakpoint.xsOnly}"
                                cols="12"
                                sm="8"
                            >
                                <v-text-field
                                    v-model="form.address"
                                    label="Residential Address"
                                    outlined
                                    filled
                                >
                                </v-text-field>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="4"
                            >
                                <v-text-field
                                    :class="{'mt-n8': $vuetify.breakpoint.xsOnly}"
                                    v-model="form.user_type"
                                    label="user_type"
                                    outlined
                                    filled
                                    disabled
                                >
                                </v-text-field>
                            </v-col>
                        </v-row>
                        <h4 class="mb-2">Agent Specialisation/Category</h4>
                        <v-row class="mt-n2">
                            <v-col
                                cols="12"
                                sm="6"
                            >
                                <v-select
                                    :items="categories"
                                    item-text="name"
                                    item-value="value"
                                    label="category"
                                    v-model="form.agent.category"
                                    outlined
                                    filled
                                >
                                </v-select>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="6"
                            >
                                <v-select
                                    :class="{'mt-n8': $vuetify.breakpoint.xsOnly}"
                                    label="Action Category"
                                    :items="categoryActions"
                                    item-text="name"
                                    item-value="value"
                                    outlined
                                    filled
                                    v-model="form.agent.action_catigory"
                                >
                                </v-select>
                            </v-col>
                        </v-row>
                        <h4 class="mb-2">Social Profile</h4>
                        <v-row class="mt-n2">
                            <v-col
                                cols="12"
                                sm="6"
                            >
                                <v-text-field
                                    color="grey ma-16"
                                    v-model="form.agent.facebook_url"
                                    label="Facebook_url"
                                    outlined
                                    filled
                                    append-icon="mdi-link-variant"
                                    
                                >
                                </v-text-field>
                            </v-col>
                            <v-col
                                cols="12"
                                sm="6"
                            >
                                <v-text-field
                                    :class="{'mt-n8': $vuetify.breakpoint.xsOnly}"
                                    color="grey ma-16"
                                    v-model="form.agent.instagram_url"
                                    label="Instagram_url"
                                    outlined
                                    filled
                                    append-icon="mdi-link-variant"
                                    
                                >
                                </v-text-field>
                            </v-col>
                             <v-col
                                cols="12"
                                sm="6"
                            >
                                <v-text-field
                                    :class="{'mt-n8': $vuetify.breakpoint.xsOnly}"
                                    color="grey ma-16"
                                    v-model="form.agent.linkedin_url"
                                    label="Linkedin_url"
                                    outlined
                                    filled
                                    append-icon="mdi-link-variant"
                                    
                                >
                                </v-text-field>
                            </v-col>
                          
                        </v-row>
                        <h4 class="mb-2">Agent Details</h4>
                        <v-row class="mt-n2">
                            <v-col
                                cols="12"
                            >
                                <v-text-field
                                    color="grey ma-16"
                                    v-model="form.agent_title"
                                    label="Title/Profile"
                                    outlined
                                    filled
                                    
                                >
                                </v-text-field>
                            </v-col>
                             <v-col
                                cols="12"
                            >
                                <v-textarea
                                    :class="{'mt-n8': $vuetify.breakpoint.xsOnly}"
                                    outlined
                                    v-model="form.agent.about"
                                    name="input-7-4"
                                    filled
                                    label="About"
                                >
                                </v-textarea>
                                <div class="float-right">
                                    <v-btn
                                        v-show="loading2 == false" 
                                        @click="updateProfile"
                                        class="py-4 mb-4 lime darken-4 ma-2 white--text"
                                    >
                                        Update Profile
                                    </v-btn>
                                    <v-progress-circular
                                        v-show="loading2"
                                        :width="3"
                                        class="mb-4"
                                        color="green"
                                        indeterminate
                                    >
                                    </v-progress-circular>
                                </div>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
                <v-form ref="form" class="white px-2 mt-10" v-show="skeLoader == false">
                    <v-container>
                        <h4 class="mb-2">Update Password</h4>
                        <v-row class="mt-n2">
                            <v-col
                                cols="12"
                                sm="6"
                            >
                                <v-text-field
                                    color="grey ma-16"
                                    v-model="new_password"
                                    :rules="[rules.required, rules.min]"
                                    hint="At least 8 characters"
                                    label="New Password"
                                    outlined
                                    filled
                                    type="password"
                                    
                                >
                                </v-text-field>
                                <div class="">
                                    <v-btn
                                        v-show="loading == false"
                                        @click="updatePassword" 
                                        class="mb-4 lime darken-4 ma-2 white--text"
                                    >
                                        Update Password
                                    </v-btn>
                                    <v-progress-circular
                                        v-show="loading"
                                        :width="3"
                                        class="mb-4"
                                        color="green"
                                        indeterminate
                                    >
                                    </v-progress-circular>
                                </div>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-col>
            <v-col class="col-12 col-md-2 grey lighten-3">
                <div class="container d-none d-md-block ml-16 mt-2"> 
                    <Notification/>	
                </div>
                <v-container class="ml-md-n4 white mt-2">
                    <div class="text-center mt-4">
                        <img height="100" :src="form.image">
                        <div class="text-subtitle-2">Maximum size 2mb</div>
                    </div>
                    <vue-dropzone 
                            id="drop1"   
                            ref="myVueDropzone" 
                            :options="dropOptions"
                            @vdropzone-error="dropzoneError"
                            @vdropzone-queue-complete="afterComplete"
                            @vdropzone-success="afterSuccess"
                            @vdropzone-sending="sendingEvent"
                        >
                    </vue-dropzone>
                    <span v-show="imageError" class="red--text">image is required</span>
                    <div class="mt-2 text-center"> 
                        <v-btn
                            v-show="loading3 == false"
                            @click="updateImage"
                            class="lime darken-4 white--text"
                            small
                        >
                                Update Image
                        </v-btn>
                        <v-progress-circular
                            v-show="loading3"
                            :width="3"
                            class="mb-4"
                            color="green"
                            indeterminate
                        >
                        </v-progress-circular>
                    </div>
                </v-container>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import SkeletonLoader from '../layout/SkeletonLoader';
import Notification from '../../components/auth/NotificationWrapper';
import { validationMixin } from 'vuelidate'
import { required} from 'vuelidate/lib/validators'
import {mapState, mapActions } from "vuex";
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import vueDropzone from "vue2-dropzone";
import UserSideBar from '../../components/auth/UserSideBar';

export default {
    components: {vueDropzone,UserSideBar, Notification, SkeletonLoader},
    mixins: [validationMixin],
    validations: {
        form: {
            first_name: { required},
            last_name: { required},
            email: { required},
            phone_number: { required},
            gender: { required},
        },   
    },
    metaInfo() {
		return {
			title: `Admin Update User - Housefinder.com.ng`,
			
		}
	},
    data: () => ({
        skeLoader: false,
        loading:false,
        loading2:false,
        loading3:false,
        imageError: false,
        new_password: '',
        form: {},
        model: 1,
        gender: [
            {name: 'Male', value:'male'},
            {name: 'Female', value:'female'}
        ],
          dropOptions: {
            url: "http://localhost/estate/public/api/user/image/update",
            autoProcessQueue: false,
            paramName: "image",
            addRemoveLinks: true,
            maxFilesize: 1,
            parallelUploads: 3,
            uploadMultiple: false,
            maxFiles: 1,
            thumbnailWidth: 150, // px
            thumbnailHeight: 150,
           
          
        },
        categories: [
            {name:'Commercial', value:'commercial'},
            {name:'Commercial & Residential', value:'commercial_residential'},
            {name:'Residential', value:'residential'},
            {name:'Consultancy', value:'consultancy'},
        ],
        categoryActions: [
            {name:'Auctions', value:'actions'},
            {name:'Complete Service', value:'complete_service'},
            {name:'Fore Closure', value:'fore_closure'},
        ],
        rules: {
          required: value => !!value || 'Required.',
          min: v => v.length >= 8 || 'Min 8 characters',
        },
    }),
    computed: {
        ...mapState(["states", "countries", "lgas", {editedUser: state => state.auth.editedUser}]),
            ...mapState({editedUser: state => state.auth.editedUser}),
        firstNameErrors () {
            const errors = []
            if (!this.$v.form.first_name.$dirty) return errors
            !this.$v.form.first_name.required && errors.push('First Name is required.')
            return errors
        }, 
        lastNameErrors () {
            const errors = []
            if (!this.$v.form.last_name.$dirty) return errors
            !this.$v.form.last_name.required && errors.push('Last Name is required.')
            return errors
        },
        phoneErrors () {
            const errors = []
            if (!this.$v.form.phone_number.$dirty) return errors
            !this.$v.form.phone_number.required && errors.push('Phone Number is required.')
            return errors
        },
        emailErrors () {
            const errors = []
            if (!this.$v.form.email.$dirty) return errors
            !this.$v.form.email.required && errors.push('Email is required.')
            return errors
        },
        genderErrors () {
            const errors = []
            if (!this.$v.form.gender.$dirty) return errors
            !this.$v.form.gender.required && errors.push('Gender is required.')
            return errors
        },    
    },
    methods:{
        ...mapActions(["getLocation", "getLga","adminUpdateUser", "findUser"]),
        onChange(event) {
            this.getLga(event);
        },
        async updateProfile(){
            this.$v.$touch()
            if (!this.$v.$invalid) {
                this.loading2 = true;
                let data = this.form;
                let response = await this.adminUpdateUser(data);
                 if(response.data.success == true) {
                    this.$toastr.s('Update successful');
                    this.loading2 = false;
                }
                else{
                    this.$toastr.e(response.data.message);
                    this.loading2 = false;
                }  
            }
            else{
                this.$toastr.e('an error occcured');
            }
            
        },
        async updatePassword(){
            if( this.$refs.form.validate()) {
                this.loading =true;
                let data = {
                        password: this.new_password,
                        id: this.form.id 
                    }
                    console.log(data)
                let response = await this.adminUpdateUser(data);
                if(response.data.success == true){
                    this.$toastr.s(response.data.message);
                    this.loading = false;
                }
                else{
                    this.$toastr.e(response.data.message);
                    this.loading = false; 
                }
            }
        },
        updateImage() {
            if (this.$refs.myVueDropzone.getQueuedFiles().length == 0) {
                this.$toastr.e("image cannot be empty ");
                this.imageError = true;
            } else {
                this.loading3 = true;
                this.$refs.myVueDropzone.processQueue()
               
            }
        },
        afterComplete() {
            this.$refs.myVueDropzone.removeAllFiles();
            
       },
        afterSuccess(file, response) {
            console.log(file)
            if(response.success == true) {
                this.form.image = response.data.image;
                this.$toastr.s(response.message);
                this.loading3 = false;
            }
            else{
                this.$toastr.e(response.message)
            }
       },
       dropzoneError(file, error, xhr){
            this.$toastr.e(error);
            console.log(file)
            console.log(xhr)
            this.skeLoader = false;
       },
       sendingEvent(file, xhr, formData){
           formData.append('id', this.form.id);
       },
        async getData(){
            
            this.skeLoader = true;
            let edited_user = await this.findUser(this.$route.params.id)
            this.form = edited_user;
            console.log(this.form )
            await this.getLocation();
            await  this.onChange(this.form.state_id);
            this.skeLoader = false;
        },
    },
    created(){
        this.getData();
        let token = localStorage.getItem('token');
        let bearer = {"Authorization": `Bearer ${token}`}
        this.dropOptions.headers = bearer;
       
    }
}
</script>